import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Test = () => {
    return (

        <>
            <Header />
            <div className='container-fluid p-0 mb-3'>
                <div className='careers_bg2 p-4 me-auto'>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item "><a href="#" className='fs-5 '>Home</a></li>
                            <li class="breadcrumb-item fs-5">General Purpose T & M Equipments</li>
                            {/* <li class="breadcrumb-item active" aria-current="page">Data</li> */}

                        </ol>
                    </nav>
                </div>
            </div>

            <div className='container mb-5'>
                <h3  className='py-4' style={{ color: '#000080',  textAlign: 'center', marginBottom: '30px' }}>

                     General Purpose Test And Measurement Equipments

                </h3>
                <div className='row mb-5'>
                    <div className='col-md-4'>
                        <div class="card border-0 shadow" style={{ width: '24rem' }}>
                            <div className='p-4'> <img src="../Assets/Digital_os.png" class="card-img-top" alt="..." /></div>
                            <div class="card-body">
                                <h5 class="card-title">Digital Storage Oscilloscope ( Handheld and Desktop)</h5>
                                <p class="card-text">
                                    <ul>
                                        <li>
                                            Bandwidth: 50Mhz – 2.5GHz
                                        </li>
                                        <li>
                                            Analog Channels: 2and 4
                                        </li>
                                        <li>
                                            Digital Channels: 16
                                        </li>
                                        <li>
                                            Sampling Rate: 500S/s – 10GS/s
                                        </li>
                                        <li>
                                            Memory: 56MPts-1GPts
                                        </li>
                                    </ul>
                                </p>
                                <a href="" class="btn text-white px-3" style={{ backgroundColor: '#e21108' }}>Download</a>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div class="card border-0 shadow" style={{ width: '24rem' }}>
                            <div className='p-4'> <img src="../Assets/waveform.png" class="card-img-top" alt="..." /></div>
                            <div class="card-body">
                                <h5 class="card-title">Waveform Generator</h5>
                                <p class="card-text">
                                    <ul>
                                        <li>
                                        Bandwidth:20MHz – 600MHz
                                        </li>
                                        <li>
                                        Sampling Rate: 200MS/s – 2.5GS/s
                                        </li>
                                        <li>
                                        No.Of Channels: 2 – 4
                                        </li>
                                        
                                        
                                    </ul>
                                </p>
                                <a href="" class="btn text-white px-3 mb-5" style={{ backgroundColor: '#e21108' }}>Download</a>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div class="card border-0 shadow" style={{ width: '24rem' }}>
                            <div className='p-4'> <img src="../Assets/spectrum_ana.png" class="card-img-top" alt="..." /></div>
                            <div class="card-body">
                                <h5 class="card-title">Spectrum Analyzers</h5>
                                <ul>
                                        <li>
                                        Bandwidth: 1.5GHz-26.5GHz
                                        </li>
                                        <li>
                                        DANL: -163dBm
                                        </li>
                                        <li>
                                        Tracking Generator: Optional
                                        </li>
                                        <li>
                                        RBW: 1Hz - 8MHz
                                        </li>
                                        
                                        
                                    </ul>
                                <a href="" class="btn text-white px-3" style={{ backgroundColor: '#e21108' }}>Download</a>
                            </div>
                        </div>
                    </div>




                </div>


                <div className='row mb-5'>
                    <div className='col-md-4'>
                        <div class="card border-0 shadow" style={{ width: '24rem' }}>
                            <div className='p-4'> <img src="../Assets/range.png" class="card-img-top" alt="..." /></div>
                            <div class="card-body">
                                <h5 class="card-title">Microohm Meter</h5>
                                <p class="card-text">
                                    <ul>
                                        <li>
                                        Measurement Range: 20kΩ – 2MΩ
                                        </li>
                                        <li>
                                        Maximum Test Rate : 60 times per second
                                        </li>
                                        <li>
                                        Connectivity: USB and RS232
                                        </li>
                                        
                                    </ul>
                                </p>
                                <a href="" class="btn text-white px-3" style={{ backgroundColor: '#e21108' }}>Download</a>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div class="card border-0 shadow" style={{ width: '24rem' }}>
                            <div className='p-4'> <img src="../Assets/lcr.png" height='250px' class="card-img-top" alt="..." /></div>
                            <div class="card-body">
                                <h5 class="card-title">LCR Meters</h5>
                                <p class="card-text">
                                    <ul>
                                        <li>
                                        Test Frequency: 10KHz – 200KHz
                                        </li>
                                        <li>
                                        Accuracy: 0.1%
                                        </li>
                                        <li>
                                        Display Count: 6-bit
                                        </li>
                                        <li>
                                        Display: 2.8” TFT
                                        </li>
                                        
                                        
                                    </ul>
                                </p>
                                <a href="" class="btn text-white px-3" style={{ backgroundColor: '#e21108' }}>Download</a>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div class="card border-0 shadow" style={{ width: '24rem' }}>
                            <div className='p-4'> <img src="../Assets/battery.png" class="card-img-top" alt="..." /></div>
                            <div class="card-body">
                                <h5 class="card-title">Battery Testers</h5>
                                <ul>
                                        <li>
                                        Voltage Range: 0.0001V – 400V
                                        </li>
                                        <li>
                                        Resistance Range : 0.001mΩ – 3kΩ
                                        </li>
                                        <li>
                                        Connectivity: RS232 and USB
                                        </li>
                                        
                                        
                                        
                                    </ul>
                                <a href="" class="btn text-white px-3" style={{ backgroundColor: '#e21108' }}>Download</a>
                            </div>
                        </div>
                    </div>


                    

                </div>

                <div className='row'>
                    <div className='col-md-4'>
                        <div class="card border-0 shadow" style={{ width: '24rem' }}>
                            <div className='p-4'> <img src="../Assets/multi.png" class="card-img-top" alt="..." /></div>
                            <div class="card-body">
                                <h5 class="card-title">	Multichannel temperature Data Logger</h5>
                                <p class="card-text">
                                    <ul>
                                        <li>
                                        Channels: 8 – 48
                                        </li>
                                        <li>
                                        Temperature: -200 – 1800 ℃
                                        </li>
                                        <li>
                                        Resolution: 0.01℃
                                        </li>
                                        <li>
                                        Scanning Speed: 10 rdgs/second
                                        </li>
                                        
                                    </ul>
                                </p>
                                <a href="" class="btn text-white px-3" style={{ backgroundColor: '#e21108' }}>Download</a>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div class="card border-0 shadow" style={{ width: '24rem' }}>
                            <div className='p-4'> <img src="../Assets/batt_sim.png" height='250px' class="card-img-top" alt="..." /></div>
                            <div class="card-body">
                                <h5 class="card-title">	Battery Simulators </h5>
                                <p class="card-text">
                                    <ul>
                                        <li>
                                        No. Of Channels: 2 – 24 
                                        </li>
                                        <li>
                                        Vmax : 6 – 20V
                                        </li>
                                        <li>
                                        Imax : 3 – 10A
                                        </li>
                                        <li>
                                        Power : 15W – 200W
                                        </li>
                                        
                                        
                                    </ul>
                                </p>
                                <a href="" class="btn text-white px-3" style={{ backgroundColor: '#e21108' }}>Download</a>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div class="card border-0 shadow" style={{ width: '24rem' }}>
                            <div className='p-4'> <img src="../Assets/source.png" class="card-img-top" alt="..." /></div>
                            <div class="card-body">
                                <h5 class="card-title">Source and Measurement Unit:</h5>
                                <ul>
                                        <li>
                                        5 in 1 (voltage source, current source, I/V/R measurement)
                                        </li>
                                        <li>
                                        Basic accuracy up to 100μV, 600 pA, 300μΩ
                                        </li>
                                        <li>
                                        Wide measurement range, 200V to 1μV, 3A to 10pA, 200MΩ to 10μΩ
                                        </li>
                                        <li>
                                        Source and sink (4-quadrant) operation 
                                        </li>
                                        <li>
                                        2/4/6-wire resistance measurement
                                        </li>
                                        
                                        
                                        
                                    </ul>
                                <a href="" class="btn text-white px-3" style={{ backgroundColor: '#e21108' }}>Download</a>
                            </div>
                        </div>
                    </div>


                    

                </div>
            </div>
            <Footer />
        </>
    )
}

export default Test