
import Header from './Header'

import { useLocation } from 'react-router-dom';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Button, Form } from 'react-bootstrap';
import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import Footer from './Footer';
import  '../cmp/css/Header.css'
import Whatapp from '../cmp/whatapp'
const Contactus = () => {
    const { pathname } = useLocation();
    const form = useRef();
    const [message, setMessage] = useState(''); // State for success/error message

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top when the component mounts or pathname changes
      }, [pathname]);
      
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_17ubdmn', 'template_i28kzbf', form.current, 'yt5BJPduWo7s0GFI5')
            .then(
                (result) => {
                    console.log(result.text);
                    setMessage('Message sent successfully');
                    resetForm();
                },
                (error) => {
                    console.log(error.text);
                    setMessage('Message sending failed');
                }
            );
    };

    const resetForm = () => {
        form.current.reset(); // Reset the form
        // Show success message for 5 seconds
        setTimeout(() => {
            setMessage('');
        }, 5000);
    };

    // Use useEffect to clear the message state after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setMessage('');
        }, 5000);

        return () => {
            clearTimeout(timer);
        };
    }, [message]);
    return (
        <>
            <Header />
            <div className='container-fluid p-0'>
                <div className='careers_bg1 p-4 me-auto'>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item "><a href="#" className='fs-5 '>Home</a></li>
                            <li class="breadcrumb-item fs-5">Contact Us</li><br />
                            {/* <li class="breadcrumb-item active" aria-current="page">Data</li> */}
                            {/* <div className='d-flex flex-column'>
                            <h3>Contacts</h3>
                            </div> */}
                        </ol>

                    </nav>
                </div>

                <div className='container py-4'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <h2 className='fw-bold' style={{ color: '#0A0080' }}>Our Offices</h2>
                            <h5>Banglore</h5>
                            <p className=' fs-5'><i className='fa fa-map-marker fs-2 me-2'></i>#006, Ground Floor, Sashank Aavaas,
                                Ambabhavani Temple Road,Shamarajpura,Vidyaranyapura Post,
                                Bangalore-560097,</p>



                            <p className='fs-5'><i className='fa fa-phone fs-4 me-1'></i> <span className='fw-bold'> Mobile:</span> +91 80880 73675</p>

                            <p className='fs-5'><i className='fa fa-envelope fs-4 me-1'></i><span className='fw-bold '>Email:</span>sales@ashtronixsolutions.com</p>

                        </div>
                        <div className='col-md-8'>

                            <h2 className='fw-bold text-center  mb-4' style={{ color: '#0A0080' }}>We’d Love to hear from you.</h2>
                            {message && <div className="success-message">{message}</div>}
                            <form ref={form} onSubmit={sendEmail} autoComplete='off'>
                                <div className='form-box px-4'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="First Name"
                                                className="mb-3"
                                            >
                                                <Form.Control type="text" placeholder="First" name="user_name"
                                                    required />
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-6'>

                                            <FloatingLabel controlId="floatingPassword" label="Last Name">
                                                <Form.Control type="text" placeholder="Last Name" name="user_last"
                                                    required />
                                            </FloatingLabel>
                                        </div>


                                        <div className='col-6'>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Mobile No."
                                                className="mb-3"
                                            >
                                                <Form.Control type="number" placeholder="Mobile" name="user_mobile"
                                                    required />
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-6'>

                                            <FloatingLabel controlId="floatingPassword" label="Company Name">
                                                <Form.Control type="text" placeholder="Company Name" name="user_com"
                                                    required />
                                            </FloatingLabel>
                                        </div>



                                        <div className='col-6'>
                                            <FloatingLabel controlId="floatingSelect" label="Industry Type">
                                                <Form.Control type="text" placeholder="Company Name" name="user_select"
                                                    required />
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-6'>

                                            <FloatingLabel controlId="floatingPassword" label="Email Id">
                                                <Form.Control type="email" placeholder="email id" className="mb-3"
                                                    name="user_email"
                                                    required
                                                />
                                            </FloatingLabel>
                                        </div>

                                        <div className='col-6'>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="City."
                                                className="mb-3"
                                            >
                                                <Form.Control type="text" placeholder="City"
                                                    name="user_city"
                                                    required

                                                />
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-6'>

                                            <FloatingLabel
                                                controlId="floatingPassword"
                                                label="Country"
                                                className="mb-3"

                                            >
                                                <Form.Control type="text" placeholder="Country" name="user_com"
                                                    required />
                                            </FloatingLabel>
                                        </div>

                                        <div className='col-12'>
                                            <FloatingLabel controlId="floatingTextarea2" label="Comments">
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Leave a comment here"
                                                    className="mb-4"
                                                    style={{ height: '150px' }}
                                                    name="user_message"
                                                    required
                                                />
                                            </FloatingLabel>
                                            <div className='text-center'>
                                                <Button variant="primary" type="submit" className='px-3 bg-success text-white'  >
                                                    Submit
                                                </Button>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>


                <div className='map'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3885.927427982521!2d77.54420887511014!3d13.103783911966406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s%23006%2C%20Ground%20Floor%2C%20Sashank%20Aavaas%2C%20Ambabhavani%20Temple%20Road%2CShamarajpura%2CVidyaranyapura%20Post%2C%20Bangalore-560097%2C!5e0!3m2!1sen!2sin!4v1698301026489!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                </div>
            </div>
            <Whatapp />
            <Footer />
        </>
    )
}

export default Contactus