import React from 'react'
import './css/Header.css'
import {  Nav, Navbar, Container, Dropdown,DropdownButton } from 'react-bootstrap';
import {Link} from 'react-router-dom'
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import Phone from '../cmp/phone'
const Header = () => {

    const customStyle = {
        
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
        zIndex: 1000,
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        width:'100%'
       
       
      };

    return (
        <>
            {/* <div className='container-fluid bg-light '>

                <div className='row py-2 px-4'>
                    <div className='col-md-6 text-start py-1 mt-2'>
                        <span className='fw-bold'>
                            <span className='fs-5'>Follows Us : </span>
                            <a href='https://www.facebook.com/profile.php?id=61551509553884&mibextid=ZbWKwL' target='_blank'><i className='fa fa-facebook' style={{ backgroundColor: '#3D7BB8', borderRadius: '50%', padding: '10px 13px', color: 'white', cursor: 'pointer', margin: '0 7px' }}></i></a>
                            <a href='https://www.linkedin.com/in/ashtronix-solutions-5a4a7828a' target='_blank'><i className='fa fa-linkedin' style={{ backgroundColor: '#3D7BB8', borderRadius: '50%', padding: '11px 12px', color: 'white', cursor: 'pointer' }}></i></a>
                        </span>

                    </div>

                    <div className='col-md-6  text-end py-1'>
                        <div className='py-1 mt-2'>
                            <span className='me-4 fs-5'><i className='fa fa-envelope me-1 fs-5'></i>sales@ashtronixsolutions.com</span>
                            <span className='fs-5'><i className='fa fa-pho me-1 fs-5'><Phone /></i>+91 80880 73675</span>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='container-fluid res-bg'  style={customStyle} >
                <div className='row py-0' style={{backgroundColor:'#1d262b'}}>
                    <div className='col-md-12'>
                        <div >

                            <Navbar expand="lg" className="bg-body-tertiary py-0">
                                <Container fluid>
                                    <Navbar.Brand  className='py-0 ps-2' href="/"><img src='../Assets/final-logo.jpg'  className='res-comlogo' style={{width:'350px'}} /></Navbar.Brand>
                                    <Navbar.Toggle aria-controls="navbarScroll" />
                                    <Navbar.Collapse id="navbarScroll" style={{width:'100%'}} >
                                        <div style={{ width: '100%' }} className=' d-flex justify-content-end' id="start-nav">
                                            <Nav
                                                className="my-2 my-lg-0"
                                                style={{ maxHeight: '300px' }}
                                                navbarScroll
                                            >

                                                <Link to="/" className='nav-link fw-bold' style={{ padding: '8px 20px' }}>Home</Link>
                                                <Link to="/aboutus" className='nav-link fw-bold' style={{ padding: '8px 20px' }}>About Us</Link>
                                                {/* <div class="dropdown">
                                                <button class="dropbtn">Products & Solutions<i className='fa fa-angle-down fs-5 mx-1 mt-1'></i></button>
                                                <div class="dropdown-content">
                                                    <a href="#">Link 1</a>
                                                    <a href="#">Link 2</a>
                                                    <a href="#">Link 3</a>
                                                </div>
                                            </div> */}
                                                <DropdownButton id="dropdown-basic-button btn-title"  title="Products" className='fw-bold'>
                                                   
                                                    <Dropdown.Item as={Link} to="/power_electronic">Power Electronics and Safety Analyser T&M Equipments</Dropdown.Item>
                                                    <Dropdown.Item  as={Link} to="/microwave_and_communication">Microwave and Communication T&M Equipments</Dropdown.Item>
                                                    <Dropdown.Item  as={Link} to="/battery_simulators">Battery Simulators And SMU Equipments </Dropdown.Item>
                                                  
                                                    <Dropdown.Item as={Link} to="/general_purpose_equipments">General Purpose T&M Equipments</Dropdown.Item>
                                                </DropdownButton>
                                                <Link to="/application" className='nav-link fw-bold' style={{ padding: '8px 20px' }}>Application</Link>
                                                <Link to="/careers" className='nav-link fw-bold' style={{ padding: '8px 20px' }}>Careers</Link>
                                                <Link to="/contactus" className='nav-link me-2 fw-bold' style={{ padding: '8px 20px' }}>Contact Us</Link>
                                                {/* <input type='search' placeholder='Search.....' style={{ borderRadius: '30px', padding: '0px 10px', outline: 'none' }} /> */}

                                            </Nav>
                                        </div>
                                        
                                    </Navbar.Collapse>
                                </Container>
                            </Navbar>
                        </div>
                    </div>
                </div>

            </div>
            {/* Carousel code start from here */}





            <div>

            </div>

        </>
    )
}

export default Header