import logo from './logo.svg';
import './App.css';
import Header from './cmp/Header';
import Home from './cmp/Home'
import Aboutus from './cmp/Aboutus';
import Careers from './cmp/Careers';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contactus from './cmp/Contactus';
import Automatedtest from './cmp/Automatedtest';
import Test from './cmp/Test';
import Batterysamulator from './cmp/Batterysamulator';
import Generalpurpose from './cmp/Generalpurpose';
import Microwave from './cmp/Microwave';
import Powerelectronics from './cmp/Powerelectronic';
import Application from './cmp/Application';
function App() {
  return (
    
      <BrowserRouter>
      <Routes>
          <Route  exact path="/" element={<Home />} />
          <Route exact  path="/aboutus" element={<Aboutus />} />
          <Route exact  path="/careers" element={<Careers />} />
          <Route exact  path="/contactus" element={<Contactus />} />
          <Route exact  path="/automated_test_equiment" element={<Automatedtest />} />
          <Route exact  path="/industrial_solution" element={<Automatedtest />} />
          <Route exact  path="/power_moniter_solutions" element={<Automatedtest />} />
          <Route exact  path="/test_bench" element={<Automatedtest />} />
          <Route exact  path="/test_measurement" element={<Test />} />
          <Route exact  path="/battery_simulators" element={<Batterysamulator />} />
          <Route exact  path="/general_purpose_equipments" element={<Generalpurpose  />} />
          <Route exact  path="/microwave_and_communication" element={<Microwave  />} />
          <Route exact  path="/power_electronic" element={<Powerelectronics  />} />
          <Route exact  path="/application" element={<Application  />} />

          



          <Route path="*" element={<df />} />
        
      </Routes>
    </BrowserRouter>
   
  );
}

export default App;
