
import React from 'react'
import Footer from './Footer'
import Header from './Header'

const Application = () => {
    return (
        <>
            <Header />
            <div className='container-fluid p-0'>
                <div className='careers_bg p-4 me-auto'>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item "><a href="#" className='fs-5 '>Home</a></li>
                            <li class="breadcrumb-item fs-5">Application</li>
                            {/* <li class="breadcrumb-item active" aria-current="page">Data</li> */}

                        </ol>
                    </nav>
                </div>
            </div>
            <div className='container py-5'>

                <div className='row'>
                    <div className='col-md-6 col-lg-3 col-xlg-4 mb-4'>
                        <div class="card">
                            <img src="../Assets/car.jpg" class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">New Energy Vehicle Industry</h5>
                                    <p class="card-text">

                                    In recent years, the new energy industry has developed rapidly under the strong support of the government,
                                     driving the rapid rise of new energy vehicles in the fields of household and commercial use.
                                    </p>
                                </div>
                        </div>

                    </div>
                    <div className='col-md-6 col-lg-3 col-xlg-4 mb-4'>
                        <div class="card">
                            <img src="../Assets/solar.jpg" class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">photovoltaic Industry</h5>
                                    <p class="card-text">
                                    As the main intelligent equipment in photovoltaic system, photovoltaic inverter has 
                                    the functions of maximum power point tracking ( MPPT ) and islanding protection,
                                     which directly affects the power generation efficiency, operation stability and 
                                     service life
                                    
                                    </p>
                                </div>
                        </div>

                    </div>
                    <div className='col-md-6 col-lg-3 col-xlg-4 mb-4'>
                        <div class="card">
                            <img src="../Assets/batttery.jpg" class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Battery Testing Industry</h5>
                                    <p class="card-text">
                                    Battery testing is essential for determining the durability and safety of batteries before they end up in the vehicles, devices, and energy storage systems consumers depend on daily. Ultimately, battery testing ensures products perform as expected.
                                    
                                    </p>
                                </div>
                        </div>

                    </div>
                    <div className='col-md-6 col-lg-3 col-xlg-4 mb-4'>
                        <div class="card">
                            <img src="../Assets/machen.jpg" class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title"> Industial Mechatronics</h5>
                                    <p class="card-text">
                                    The anodized layer is grown by passing direct current (DC) from the anodizing rectifier through an electrolytic solution.
                                     The part being anodized is the positive electrode, the anode. 
                                    
                                    </p>
                                </div>
                        </div>

                    </div>

                    <div className='col-md-6 col-lg-3 col-xlg-4'>
                        <div class="card">
                            <img src="../Assets/aero.jpg" class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Aerospace Industry</h5>
                                    <p class="card-text">
                                    Ever wondered what kind of power an aircraft uses when parked at the airport stand.

　Normally the aircraft generates it own power, but when parked with the engines switched off power provided by the airport would be connected to the plane.
                                    
                                    </p>
                                </div>
                        </div>

                    </div>

                </div>


            </div>

            <Footer />
        </>
    )
}

export default Application