import { useState } from 'react'
import Header from './Header'
import Nav from 'react-bootstrap/Nav';
import Footer from './Footer';
import Whatapp from '../cmp/whatapp'
import { Link } from 'react-router-dom';

const Microwave = () => {

    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    }
    return (
        <>
            <Header />
            <div>
                <div className='container-fluid p-0'>
                    <div className='careers_bg2 p-4 me-auto'>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item "><a href="#" className='fs-5 '>Home</a></li>
                                <li class="breadcrumb-item fs-5">Microwave And Communication T&M Equipments</li>
                                {/* <li class="breadcrumb-item active" aria-current="page">Data</li> */}

                            </ol>
                        </nav>
                    </div>
                </div>


                {/* <div className='container py-5'>
      <Nav variant="pills" defaultActiveKey="/home">
      <Nav.Item>
        <Nav.Link href="#tab-1">Automation Test Equipment</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-1">Industrial Automation</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-2">Power Moniterning Solution</Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link eventKey="link-3">Test Benches & Lab Solutions</Nav.Link>
      </Nav.Item>
      <Nav.Item>
       
      </Nav.Item>
    </Nav>

      </div> */}
                <div className='bg-light'>
                    <div className='container py-5'>
                        <div>
                            <ul className="nav" variant="pills" >
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`} onClick={() => handleTabClick('tab1')} style={{ border: 'none', marginBottom: '20px' }}>
                                        RF Analyzers
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`} onClick={() => handleTabClick('tab2')} style={{ border: 'none' }}>
                                        RF Power Moniters
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`} onClick={() => handleTabClick('tab3')} style={{ border: 'none' }}>
                                        Handheld Signal Generator
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab4' ? 'active' : ''}`} onClick={() => handleTabClick('tab4')} style={{ border: 'none' }}>
                                        Modular VNA
                                    </button>
                                </li>


                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab5' ? 'active' : ''}`} onClick={() => handleTabClick('tab5')} style={{ border: 'none' }}>
                                        USB VNA
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab6' ? 'active' : ''}`} onClick={() => handleTabClick('tab6')} style={{ border: 'none' }}>
                                        Vector Signal Analyzer
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab7' ? 'active' : ''}`} onClick={() => handleTabClick('tab7')} style={{ border: 'none' }}>
                                        Vector Signal Generator
                                    </button>
                                </li>
                            </ul>

                            <div className="tab-content">
                                {activeTab === 'tab1' && <div className='border p-3 px-4'>
                                    <div>
                                        <h3 className='fw-normal mb-4'>RF Analyzers</h3>

                                        <h2>Handheld Spectrum Analyzers and RF Cable & Antenna <br />Testers</h2>

                                        <p className='fs-5'>
                                            Measure with confidence and withstand your toughest working environments
                                            with Bird's SiteHawk RF cable & antenna testers (CAT) and SignalHawk spectrum
                                            analyzers. Install and maintain wireless systems, perform in-depth troubleshooting,
                                            conduct routine maintenance and ensure your team's preparedness with Bird's portable analyzers.
                                        </p>

                                        <h2>Why choose  RF Analyzers?</h2>
                                        <ul>
                                            <li className='fs-5'>
                                                Measure with precision and confidence with over 80 years of Bird RF expertise.
                                            </li>
                                            <li className='fs-5'>
                                                Purpose-built analyzers designed with detailed ergonomics for field use.
                                            </li>
                                            <li className='fs-5'>
                                                Comprehensive kits created for the work you do everyday.
                                            </li>
                                            <li className='fs-5 mb-4'>
                                                Reduce measurement complexity and time-to-repair with straightforward, clear results.
                                            </li>



                                        </ul>

                                        <hr className='mb-5' />
                                        <div className='row'>

                                            <div className='col-md-4 mb-5'>

                                                <div className='text-center mb-5'><img src='../Assets/analyzer.png' style={{ width: '200px' }} /></div>

                                            </div>

                                            <div className='col-md-6'>
                                                <h3 className='mb-3'>
                                                    Cable and Antenna Testers
                                                </h3>
                                                <h5>Features</h5>
                                                <p className='fs-5'>
                                                    <ul>
                                                        <li>
                                                            Models up to 9 GHz
                                                        </li>
                                                        <li>
                                                            Distance to Fault, return loss, cable loss
                                                        </li>
                                                        <li>
                                                            Frequency domain reflectometry
                                                        </li>
                                                        <li>
                                                            Compatible with a variety of sensors and included in some kits.
                                                        </li>
                                                        <li>
                                                            Lightweight at 1.98 lbs. (0.9 kg)
                                                        </li>

                                                    </ul>

                                                </p>


                                            </div>


                                            <div className='col-md-4 mb-5'>

                                                <div className='text-center mb-5'><img src='../Assets/spect-analyzers.png' className='' style={{ width: '200px' }} /></div>

                                            </div>

                                            <div className='col-md-6 mb-5'>
                                                <h3 className='mb-3'>
                                                    Spectrum Analyzers
                                                </h3>
                                                <h5>Features</h5>
                                                <p className='fs-5'>
                                                    <ul>
                                                        <li>
                                                            Models up to 7.5 GHz
                                                        </li>
                                                        <li>
                                                            Adjacent Channel Power Ratio
                                                        </li>
                                                        <li>
                                                            Channel Power
                                                        </li>
                                                        <li>
                                                            Occupied Bandwidth
                                                        </li>
                                                        <li>
                                                            N dB Down Bandwidth
                                                        </li>
                                                        <li>
                                                            Compatible with a variety of sensors.
                                                        </li>
                                                        <li>
                                                            Lightweight at 2.5 lbs. (40 oz), approx.
                                                        </li>
                                                        <li>
                                                            Bird RF Meter App
                                                        </li>


                                                    </ul>

                                                </p>


                                            </div>

                                            <div className='col-md-4 mb-5'>

                                                <div className='text-center mb-5'><img src='../Assets/test-kit.png' className='' style={{ width: '200px' }} /></div>

                                            </div>

                                            <div className='col-md-6'>
                                                <h3 className='mb-3'>
                                                    RF Analyzer Test Kits
                                                </h3>
                                                <p className='fs-5 m-0'>RF testing for cable & antenna installation and maintenance.</p>
                                                <h5>Kit Includes</h5>
                                                <p className='fs-5'>
                                                    <ul>
                                                        <li>
                                                            SK-6000-TC, 1 MHz- 6 GHz Cable and Antenna Analyzer
                                                        </li>
                                                        <li>
                                                            SH-60S-TC or SH-60S-AOA, 9 kHz - 6 GHz Spectrum Analyzer
                                                        </li>
                                                        <li>
                                                            RF Sensor, RF Termination, Cal-Combo and accessories
                                                        </li>

                                                    </ul>

                                                </p>


                                            </div>




                                            <div className='px-4'>
                                                <div className='auto-img d-flex justify-content-center align-items-center'>
                                                    <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>



                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                }


                                {activeTab === 'tab2' && <div className='border p-3 bg-light'>


                                    <div>
                                        <h3 className='fw-normal mb-4'>RF Power Monitors</h3>

                                        <h2>Monitor, Measure and Protect your RF System</h2>

                                        <p className='fs-5'>
                                            Bird's RF Power Monitor family includes a range of products
                                            that not only measure power and VSWR but also provide hard
                                            contact alarms to protect your system in the event of a system failure – the
                                            IP-enabled, BPME is even capable of emailing a failure notification.
                                        </p>



                                        <hr className='mb-5' />
                                        <div className='row'>

                                            <div className='col-md-4 mb-5'>

                                                <div className='text-center mb-5'><img src='../Assets/power-monitors.jpg' style={{ width: '200px' }} /></div>

                                            </div>

                                            <div className='col-md-6'>
                                                <h3 className='mb-3'>
                                                    Broadcast Power Monitors
                                                </h3>

                                                <p className='fs-5'>
                                                    <ul>
                                                        <li>
                                                            BPME Series, Broadcast Power Monitors accurately monitor RF power and VSWR for a wide range of frequencies and power levels.
                                                        </li>



                                                    </ul>

                                                </p>


                                            </div>


                                            <div className='col-md-4 mb-5'>

                                                <div className='text-center mb-5'><img src='../Assets/monitor-1.png' className='' style={{ width: '200px' }} /></div>

                                            </div>

                                            <div className='col-md-6 mb-5'>
                                                <h3 className='mb-3'>
                                                    Channel Power Monitor
                                                </h3>

                                                <p className='fs-5'>
                                                    <ul>
                                                        <li>
                                                            Be assured that your communication system is up and running at all times with Bird’s
                                                            new Channel Power Monitor. It provides you with continuous information on the health
                                                            of each component of your system that is accessible on any computer or tablet on the
                                                            network or even the phone in your pocket.
                                                        </li>

                                                    </ul>

                                                </p>


                                            </div>

                                            <div className='col-md-4 mb-5'>

                                                <div className='text-center mb-5'><img src='../Assets/mon.png' className='' style={{ width: '200px' }} /></div>

                                            </div>

                                            <div className='col-md-6'>
                                                <h3 className='mb-3'>
                                                    Transmitter Power Monitors
                                                </h3>


                                                <p className='fs-5'>
                                                    <ul>
                                                        <li>
                                                            TPM Series, Transmitter Power Monitors the latest broadcast solutions from Bird
                                                        </li>


                                                    </ul>

                                                </p>


                                            </div>




                                            <div className='px-4'>
                                                <div className='auto-img d-flex justify-content-center align-items-center'>
                                                    <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>



                                                </div>
                                            </div>
                                        </div>

                                    </div>



                                </div>}
                                {activeTab === 'tab3' && <div className='border p-3'>


                                    <div className='p-4'>
                                        <h3 className='fw-bold mb-4'>THE FUTURE OF HANDHELD SIGNAL GENERATOR IS HERE: GH-60</h3>
                                        <div className='row'>
                                            <h3>Products overview</h3>
                                            <div className='col-md-4 mb-5'>

                                                <div className='text-center mb-5'><img src='../Assets/hand-1.webp' style={{ width: '200px' }} /></div>

                                            </div>

                                            <div className='col-md-6'>
                                                <p className='fs-5'>
                                                    Introducing the GH-60 Handheld Signal Generator - the ultimate solution
                                                    for portable, high-performance signal generation. This comprehensive
                                                    upgrade to our handheld signal generator line combines the perfect balance of power,
                                                    portability, and control. The compact design of the GH-60 is engineered for easy single-handed
                                                    operation while still delivering exceptional RF performance and a wide range of signal generation
                                                    functions. It is perfect for use in various communication industries, such as manufacturing, R&D,
                                                    and integration. The GH-60 can deliver bespoke signal services and meet the majority of signal simulation
                                                    requirements because of its high-performance platform. The GH-60 Handheld Signal Generator can help you
                                                    improve your signal creation talents.
                                                </p>


                                            </div>

                                            <h3>Keys facts</h3>
                                            <div className='col-md-4 mb-5'>

                                                <div className='text-center mb-5'><img src='../Assets/hand-1.webp' className='' style={{ width: '200px' }} /></div>

                                            </div>

                                            <div className='col-md-8 mb-5'>

                                                <div className='row '>
                                                    <div className='col-6'>
                                                        <p>Frequency range</p>
                                                        <h4>
                                                            10MHz - 6GHz
                                                        </h4>



                                                        <p>Support communication standard signal type</p>
                                                        <h4>
                                                            GSM | WCDMA | TDD-LTE | FDD-LTE NB-IoT | LoRa | 5GNR
                                                        </h4>


                                                        <p>High portability</p>
                                                        <h4>
                                                            Small size(197*93*61 mm)，light weight (0.9kg);
                                                        </h4>


                                                        <p>GeneMini is an Android hand-held signal generator</p>
                                                        <h4>
                                                            It features simple operation
                                                        </h4>



                                                    </div>

                                                    <div className='col-6'>
                                                        <p>Frequency range</p>
                                                        <h4>
                                                            10MHz - 6GHz
                                                        </h4>



                                                        <p>Support communication standard signal type</p>
                                                        <h4>
                                                            GSM | WCDMA | TDD-LTE | FDD-LTE NB-IoT | LoRa | 5GNR
                                                        </h4>


                                                        <p>High portability</p>
                                                        <h4>
                                                            Small size(197*93*61 mm)，light weight (0.9kg);
                                                        </h4>


                                                        <p>GeneMini is an Android hand-held signal generator</p>
                                                        <h4>
                                                            It features simple operation
                                                        </h4>



                                                    </div>

                                                </div>



                                            </div>

                                            <div className='col-md-12 mb-5'>
                                                <h2>Control Elements</h2>
                                                <div className='text-center mb-5'><img src='../Assets/hand-2.webp' className='' style={{ width: '400px' }} /></div>

                                            </div>
                                            <div className='col-md-6'>
                                                <h3>Features</h3>
                                                <ul>
                                                    <li className='fs-5 mb-3'>
                                                        The GH-60 is designed to be small and lightweight, making it easy to carry around. It also features a long battery life, ensuring that users can use it for extended periods without needing to recharge constantly.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        GH-60 is based on the Android operating system, which allows users to install additional applications to suit their specific needs. The open platform also allows for second development, which means that users can customize and extend the functionality of the device.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        Modulation is a crucial signal modulation method in modern telecommunications, and the GH-60 is capable of outputting a wide variety of digitally modulated signals. This includes advanced modulation methods, such as Quadrature Amplitude Modulation (QAM), Phase Shift Keying (PSK), and Frequency Shift Keying (FSK), all of which have better anti-interference and safety capabilities.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The GH-60 is designed with an easy-to-use interface that allows users to quickly and easily access all of the device's functionality. It features USB, a power interface, and LED control elements, which allow for fast and convenient connectivity and control.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        GH-60 has a high-resolution screen, which gives a better visual representation of the signal and makes it easy for the users to monitor the signal at all times.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        On/Off button: For easy and fast power on and off.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        This feature allows the device to output a wide range of signal generation functions.
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className='col-md-6'>
                                                <h3>Benefits</h3>
                                                <ul>
                                                    <li className='fs-5 mb-3'>
                                                        The GH-60's combination of high portability and high performance makes it an ideal solution for users who need to perform signal generation tests in a wide range of environments. Whether working in a lab or out in the field, the GH-60 is designed to be easy to take with you and use for extended periods
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The android-based system allows users to install other applications according to their needs, and it's an open platform that allows for second development. This means that users can customize and extend the functionality of the device to suit their specific requirements better.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        With rich functionality for general digital modulation, the GH-60 can output a variety of digitally modulated signals, providing more robust anti-interference and safety. The variety of digital modulation methods provides better anti-interference and security, thus making it a suitable device for various use cases.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The USB, power interface, and LED control elements, combined with the HD screen and On/Off button, make the GH-60 easy to operate. Users can easily navigate the device and quickly access all of its functionality.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The RF output allows for a wide range of signal generation functions, which can be useful for various applications. This feature makes it a versatile device for many signal-generation scenarios.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        Overall, the Sanko GH-60 Handheld Signal Generator is a powerful, portable, and highly customizable signal generation device that is ideal for a wide range of applications in communication fields, including integration, R&D, and manufacturing.
                                                    </li>

                                                </ul>
                                            </div>


                                            <div className='px-4'>
                                                <div className='auto-img d-flex justify-content-center align-items-center'>
                                                    <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>



                                                </div>
                                            </div>
                                        </div>

                                    </div>






                                </div>}

                                {activeTab === 'tab4' && <div className='border p-3'>


                                    <div className='p-4'>
                                        <h3 className='fw-bold mb-4'>GET ACCURATE AND RELIABLE RF TESTING RESULTS WITH THE BN900-SERIES MODULAR VECTOR NETWORK ANALYZER.</h3>
                                        <div className='row'>
                                            <h3>Products overview</h3>
                                            <div className='col-md-4 mb-5'>

                                                <div className='text-center mb-5'><img src='../Assets/vna.webp' style={{ width: '360px' }} /></div>

                                            </div>

                                            <div className='col-md-6'>
                                                <p className='fs-5'>

                                                    The BN900 Modular Vector Network Analyzer is an advanced RF testing instrument suitable for a variety of applications such as laboratory, scientific research, teaching, manufacturing, and system integration. Its frequency range is from 300kHz to 6.5/8.5GHz, and it has a large dynamic range of  125 dB @(IFBW=10 Hz), typ. 130 dB, and low trace noise of 2 mdB rms @(IFBW=3 kHz), making it ideal for different types of testing requirements.

                                                    This instrument has a high measurement speed of 42 μs/point @(IFBW=500 kHz) and effective directivity of 42 dB, ensuring accurate and reliable measurement results. It is also easy to carry due to its compact size design and has an open interface allowing for secondary development. Additionally, it supports the standard VISA protocol, making it easy to integrate into existing systems.

                                                    The BN900-Series Modular Vector Network Analyzer is a versatile and cost-effective option for universities, manufacturers, R&D, and Telco related to radio frequency products. It has a performance similar to a Bench-top vector network analyzer and provides high-resolution bandwidth, which saves time and money by delivering accurate and reliable measurement results.
                                                </p>


                                            </div>

                                            <h3>Keys facts</h3>
                                            <div className='col-md-4 mb-5'>

                                                <div className='text-center mb-5'><img src='../Assets/vna-2.webp' className='' style={{ width: '100%' }} /></div>

                                            </div>

                                            <div className='col-md-8 mb-5'>

                                                <div className='row '>
                                                    <div className='col-6'>
                                                        <p>Frequency range</p>
                                                        <h4>
                                                            300kHz to 6.5/8.5GHz
                                                        </h4>



                                                        <p>Low trace noise</p>
                                                        <h4>
                                                            2 mdB rms @(IFBW=3 kHz )
                                                        </h4>


                                                        <p>Large dynamic range</p>
                                                        <h4>
                                                            125 dB @(IFBW=10 Hz)，typ. 130 dB
                                                        </h4>






                                                    </div>

                                                    <div className='col-6'>
                                                        <p>Measurement speed</p>
                                                        <h4>
                                                            42 μs/point @(IFBW=500 kHz)
                                                        </h4>



                                                        <p>High effective directivity</p>
                                                        <h4>
                                                            42 dB
                                                        </h4>



                                                        <h4>
                                                            Support for standard VISA protocol
                                                        </h4>






                                                    </div>

                                                </div>



                                            </div>

                                            <div className='col-md-12 mb-5'>
                                                <h2>Control Elements</h2>
                                                <div className='text-center mb-5'><img src='../Assets/vna-3.webp' className='' style={{ width: '100%' }} /></div>

                                            </div>
                                            <div className='col-md-6'>
                                                <h3>Features</h3>
                                                <ul>
                                                    <li className='fs-5 mb-3'>
                                                        The BN900-Series is a modular vector network analyzer with 4 test ports and a frequency range of 300kHz to 6.5/8.5GHz.                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        It has a dynamic range of up to 130 dB and an effective directivity of 38-49 dB.                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The instrument has a measurement accuracy of +/- 0.2 dB/2° for transmission measurements and +/- 0.4 dB/3° for reflection measurements.                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        It has a fast measurement time of 42μs per point and a trace noise of 2mdB rms.                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The instrument is equipped with various external ports for trigger input, reference input, and reference output.                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        It has a working temperature range of +5°C to +40°C and a storage temperature range of -20°C to +60°C.                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The system calibration interval is three years, and the instrument is available with various optional calibration modules, RF cables, and other functions.                                                    </li>
                                                </ul>
                                            </div>

                                            <div className='col-md-6'>
                                                <h3>Benefits</h3>
                                                <ul>
                                                    <li className='fs-5 mb-3'>
                                                        A versatile measurement range of 300kHz to 6.5/8.5GHz allows for a wide range of applications, including testing of RF/microwave devices and components such as filters, amplifiers, antennas, and cables.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        Four N-type female test ports enable multiport testing and simultaneous measurement of S-parameters for up to four devices.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        A high impedance of 50Ω provides accurate and reliable measurements for a variety of devices.                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        A high-frequency accuracy of ±5ppm and frequency resolution of 1Hz ensure precise and accurate measurements, even at high frequencies.                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        A large number of measurement points (2-20,001) and measurement bandwidth (1Hz to 2MHz) allow for a detailed analysis of device performance                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        A high dynamic range (112 dB to 125 dB) ensures accurate measurement of weak signals and suppression of strong signals.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        Effective directivity, source match, and load match allow for accurate measurement of device performance and characterization.

                                                    </li>

                                                    <li className='fs-5 mb-3'>
                                                        Accurate transmission and reflection measurements with magnitude and phase accuracy of up to 0.2dB/2°.

                                                    </li>

                                                    <li className='fs-5 mb-3'>
                                                        Trace stability and low trace noise (2mdB rms) ensure reliable and repeatable measurements.

                                                    </li>

                                                    <li className='fs-5 mb-3'>
                                                        Fast measurement time per point (42μs) allows for efficient testing and analysis.

                                                    </li>


                                                    <li className='fs-5 mb-3'>
                                                        Various calibration kits and cables are available as ordering options to further enhance measurement accuracy and flexibility.

                                                    </li>


                                                    <li className='fs-5 mb-3'>
                                                        Temperature stability of 0.01dB/℃ ensures accurate measurements even in varying temperature conditions.

                                                    </li>


                                                    <li className='fs-5 mb-3'>
                                                        External trigger input ports and reference input/output ports allow for synchronization with other devices and reference signals.

                                                    </li>


                                                    <li className='fs-5 mb-3'>
                                                        Robust design and system calibration intervals of 3 years ensure long-term reliability and accuracy.

                                                    </li>


                                                </ul>
                                            </div>


                                            <div className='px-4'>
                                                <div className='auto-img d-flex justify-content-center align-items-center'>
                                                    <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>



                                                </div>
                                            </div>
                                        </div>

                                    </div>



                                </div>}

                                {activeTab === 'tab5' && <div className='border p-3'>


                                    <div className='p-4'>
                                        <h3 className='fw-bold mb-4'>INTRODUCING THE BN100+ - THE COMPACT USB VECTOR NETWORK ANALYZER WITH EXCEPTIONAL PERFORMANCE.</h3>
                                        <div className='row'>
                                            <h3 className='mb-3'>Products overview</h3>
                                            <div className='col-md-4 mb-5'>

                                                <div className='text-center mb-5'><img src='../Assets/usb.webp' style={{ width: '360px' }} /></div>

                                            </div>

                                            <div className='col-md-8'>
                                                <p className='fs-5'>

                                                    The BN100+ is a compact and high-performing Vector Network Analyzer that offers a wide range of capabilities in a small package. It is capable of replacing traditional bench-top VNA and is ideal for laboratory, manufacturing, and research and development settings. Its user-friendly design and features make it easy to set up and use, with LED indication for better visibility.

                                                    The BN100+ has a frequency range of 300KHz to 6.5GHz / 8.5GHz, and it is equipped with 2 Test Ports and 1 Ground Terminal. It offers superior performance thanks to its wide dynamic range, low noise level, and high-resolution scanning capabilities. With various analysis functions, including S11, S21, and other advanced measurements, the BN100+ is versatile and powerful.

                                                    The software of the BN100+ is intuitive and offers various analysis functions and modes. The USB interface makes it easy to connect to a PC, and the instrument also supports remote control and data storage capabilities, which enable accessible data collection and analysis. An economical solution for accurate RF testing, the BN100+ is a cost-effective alternative to traditional bench-top VNA.
                                                </p>


                                            </div>

                                            <h3 className='mb-4'>Keys facts</h3>
                                            <div className='col-md-4 mb-5'>

                                                <div className='text-center mb-5'><img src='../Assets/usb-1.webp' className='' style={{ width: '100%' }} /></div>

                                            </div>

                                            <div className='col-md-8 mb-5'>

                                                <div className='row '>
                                                    <div className='col-6'>
                                                        <p>Frequency range</p>
                                                        <h4>
                                                            300KHz to 6.5GHz / 8.5GHz
                                                        </h4>



                                                        <p>High Measurement Speed</p>
                                                        <h4>
                                                            120 μs/point (IFBW=30 kHz)
                                                        </h4>


                                                        <p>Effective directivity
                                                        </p>
                                                        <h4>
                                                            42dB
                                                        </h4>


                                                        <p>Large dynamic range
                                                        </p>
                                                        <h4>
                                                            120dB
                                                        </h4>






                                                    </div>

                                                    <div className='col-6'>
                                                        <p>Support standard</p>
                                                        <h4>
                                                            VISA
                                                        </h4>



                                                        <p>Low Trace Noise</p>
                                                        <h4>5 mdB rms (IFBW=3 kHz)
                                                        </h4>


                                                        <p>Low Noise Level</p>
                                                        <h4>
                                                            -120 dBm (IFBW=10 Hz)
                                                        </h4>

                                                        <p>Low Power Consumption</p>
                                                        <h4>
                                                            18W
                                                        </h4>






                                                    </div>

                                                </div>



                                            </div>

                                            <div className='col-md-12 mb-5'>
                                                <h2 className='mb-4'>Control Elements</h2>
                                                <div className='text-center mb-5'><img src='../Assets/usb-2.webp' className='' style={{ width: '100%' }} /></div>

                                            </div>
                                            <div className='col-md-6'>
                                                <h3>Features</h3>
                                                <ul>
                                                    <li className='fs-5 mb-3'>
                                                        Able to take the place of a benchtop VNA
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        Minimum budget requirement

                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        Suitable for industrial, research & development, and laboratory uses
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        Simple implementation, flexible system upgrading, and small design
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        LED indication for better visibility                                                  </li>
                                                    <li className='fs-5 mb-3'>
                                                        TEST PORT: Ground terminal, Test Port 1, and Test Port 2
                                                    </li>

                                                    <li className='fs-5 mb-3'>
                                                        Wide dynamic range and low noise level

                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        High-resolution scanning


                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        Laboratory and research grade performance


                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The frequency range of 300KHz to 6.5GHz


                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        USB interface for easy connectivity to the PC

                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        User-friendly software with various analysis functions and modes


                                                    </li>
                                                </ul>
                                            </div>

                                            <div className='col-md-6'>
                                                <h3>Benefits</h3>
                                                <ul>
                                                    <li className='fs-5 mb-3'>
                                                        The BN100+ VNA offers a compact form factor without compromising functionality and is capable of replacing bench-top VNA.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        It is ideal for laboratory, manufacturing, and research and development purposes, which makes it suitable for a variety of test environments.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The compact design and implementation simplicity of BN100+ makes it easy to set up and use, and it allows for various system upgrades.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The LED indication and Test Port with Ground terminal, Test Port 1, and Test Port 2 provide better visibility.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The BN100+ VNA offers a cost-effective solution for accurate RF testing.                                                  </li>
                                                    <li className='fs-5 mb-3'>
                                                        It has a wide dynamic range, low noise level, and high-resolution scanning, which makes it a powerful instrument for evaluating the performance of RF devices and circuits.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The USB interface allows for easy connectivity to a PC, and the user-friendly software with various analysis functions and modes makes it easy to operate.

                                                    </li>

                                                    <li className='fs-5 mb-3'>
                                                        Remote control and data storage capabilities allow for accessible data collection and analysis.

                                                    </li>

                                                </ul>
                                            </div>


                                            <div className='px-4'>
                                                <div className='auto-img d-flex justify-content-center align-items-center'>
                                                    <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>



                                                </div>
                                            </div>
                                        </div>

                                    </div>



                                </div>}


                                {activeTab === 'tab6' && <div className='border p-3'>


                                    <div className='p-4'>
                                        <h3 className='fw-bold mb-4'>
                                            INTRODUCING THE BA100 - THE COMPACT USB RF VECTOR SIGNAL ANALYZER WITH EXCEPTIONAL PERFORMANCE
                                        </h3>
                                        <div className='row'>
                                            <h3 className='mb-3'>Products overview</h3>
                                            <div className='col-md-4 mb-5'>

                                                <div className='text-center mb-5'><img src='../Assets/vec-ana.webp' style={{ width: '360px' }} /></div>

                                            </div>

                                            <div className='col-md-8'>
                                                <p className='fs-5'>
                                                    The BA100 USB RF Vector Signal Analyzer, also known as Vector Network Organizer, is a compact and robust solution for RF signal testing. With a small form factor and zero compromise in functionality, the BA100 offers excellent testing performance and measurement sensitivity, making it suitable for a wide range of RF signals. The BA100 is a versatile instrument capable of general spectrum testing, demodulation of movements such as LTE, FM, and Digital Signals, as well as Vector Network Analysis. It also offers system integration options, including a PCB version module product and an API library for secondary development. The BA100 is an ideal solution for professionals in need of a reliable and high-performing Vector Network Organizer.

                                                </p>


                                            </div>

                                            <h3 className='mb-4'>Keys facts</h3>
                                            <div className='col-md-4 mb-5'>

                                                <div className='text-center mb-5'><img src='../Assets/vec-ana-1.webp' className='' style={{ width: '100%' }} /></div>

                                            </div>

                                            <div className='col-md-8 mb-5'>

                                                <div className='row '>
                                                    <div className='col-6'>
                                                        <p>Frequency range</p>
                                                        <h4>
                                                            9kHz to 6GHz
                                                        </h4>



                                                        <p>Signal demodulation</p>
                                                        <h4>
                                                            Digital signal
                                                        </h4>



                                                        <h4>
                                                            Small size (180*50*290mm) and light weight (1.8kg)
                                                        </h4>









                                                    </div>

                                                    <div className='col-6'>
                                                        <p>DANL</p>
                                                        <h4>
                                                            -168 dBm @1GHz
                                                        </h4>




                                                        <h4>API library is provided for secondary development
                                                        </h4>








                                                    </div>

                                                </div>



                                            </div>

                                            <div className='col-md-12 mb-5'>
                                                <h2 className='mb-4'>Control Elements</h2>
                                                <div className='text-center mb-5'><img src='../Assets/vec-ana-2.webp' className='' style={{ width: '100%' }} /></div>

                                            </div>
                                            <div className='col-md-6'>
                                                <h3>Features</h3>
                                                <ul>
                                                    <li className='fs-5 mb-3'>
                                                        The BA100 is designed to be compact and easy to set up, making it ideal for on-the-go testing and for use in tight spaces. Its small size also makes it easy to carry around and transport.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        With its excellent testing performance and measurement sensitivity, the BA100 is able to analyze a wide range of RF signals accurately.

                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The BA100 supports various analysis functions and modes which makes it suitable for general spectrum testing, Digital Signals, and other advanced measurements. This means that the BA100 can be used for a variety of different testing scenarios.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The BA100 offers system integration options, including three other product models: full-size USB module, small-size USB module, and PCB module, which makes it easy to integrate the analyzer into existing systems.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        BA100 provides an open API interface, which allows users to conduct secondary development and create customized applications that extend the functionality of the BA100 to suit their specific needs.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        BA100 is specifically designed to support telecommunications and general demodulation, which makes it an essential tool for identifying problems and troubleshooting issues in these areas.                                                </li>
                                                    <li className='fs-5 mb-3'>
                                                        BA100 offers an intuitive and easy-to-use graphical user interface, making it easy for users to access the powerful features and functionalities of the analyzer.
                                                    </li>


                                                </ul>
                                            </div>

                                            <div className='col-md-6'>
                                                <h3>Benefits</h3>
                                                <ul>
                                                    <li className='fs-5 mb-3'>
                                                        The compact design of the BA100 makes it easy to carry to the field and set up quickly, saving valuable time and resources.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The BA100 is the perfect choice for professionals working in the telecommunications sector, as well as for research and development in this area, thanks to its adaptability, performance, and measurement sensitivity.                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The system integration options and open API interface of the BA100 allow for deep integration into existing systems and workflows and make it easy to customize it to meet specific needs.                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The comprehensive telecommunications and general demodulation support of the BA100 make it an essential tool for identifying problems and troubleshooting issues.                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                        The easy-to-use graphical user interface makes it easy for users to access the powerful features and functionalities of the analyzer.                                                  </li>

                                                </ul>
                                            </div>


                                            <div className='px-4'>
                                                <div className='auto-img d-flex justify-content-center align-items-center'>
                                                    <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>



                                                </div>
                                            </div>
                                        </div>

                                    </div>



                                </div>}


                                {activeTab === 'tab7' && <div className='border p-3'>


                                    <div className='p-4'>
                                        <h3 className='fw-bold mb-4'>
                                            GENERATE PRECISE AND HIGH-QUALITY SIGNALS WITH THE BG100 VECTOR SIGNAL GENERATOR: THE ULTIMATE TOOL FOR RESEARCH, MANUFACTURING, TESTING, AND MEASUREMENT, AND ELECTRONIC COUNTERMEASURES
                                        </h3>
                                        <div className='row'>
                                            <h3 className='mb-3'>Products overview</h3>
                                            <div className='col-md-4 mb-5'>

                                                <div className='text-center mb-5'><img src='../Assets/vec-gen.webp' style={{ width: '360px' }} /></div>

                                            </div>

                                            <div className='col-md-8'>
                                                <p className='fs-5'>
                                                    The BG100 Vector Signal Generator, also known as Vector Network Organizer, is a high-performance RF signal generator that offers unmatched purity and precision in signal generation. It is designed to generate a wide range of signal types, including arbitrary wave signals, continuous wave signals, common vector signals, digital signals, standard wireless vector signals, and standard radio signals, as well as the ability to generate custom signals based on user requirements. Additionally, the BG100 is also able to do Vector Network Analysis.

                                                    This powerful signal generator and Vector Network Organizer is suitable for a wide range of applications, including educational practices, wireless monitoring, mobile communication, aerospace, and the defense industry. Whether you are in research, manufacturing, testing, and measurement or electronic countermeasures, the BG100 can satisfy most of your signal simulation and Vector Network Analysis needs.

                                                    Designed with a user-friendly interface, the BG100 offers easy navigation and control of all its features and functions. It has a large display, easy to read and transparent, to get all the information you need from your waveform in one place. Also, It can be easily integrated with other test equipment to provide even more functionality.

                                                    With its high-performance specifications, comprehensive signal generation and Vector Network Analysis capabilities, and easy-to-use interface, the BG100 Vector Signal Generator and Vector Network Organizer is a versatile and powerful tool that can help you achieve the results you need in any RF signal generation and Vector Network Analysis application.

                                                </p>


                                            </div>

                                            <h3 className='mb-4'>Keys facts</h3>
                                            <div className='col-md-4 mb-5'>

                                                <div className='text-center mb-5'><img src='../Assets/vec-gen-1.webp' className='' style={{ width: '100%' }} /></div>

                                            </div>

                                            <div className='col-md-8 mb-5'>

                                                <div className='row '>
                                                    <div className='col-6'>
                                                        <p>Frequency range</p>
                                                        <h4>
                                                        10MHz - 6GHz (can upgrade to 1MHz - 6GHz)
                                                        </h4>



                                                        <p>Full range of common digital modulation</p>
                                                        <h4>
                                                        BPSK, QPSK, OQPSK, 8PSK, 16QAM, 32QAM, 64QAM, MSK, and FSK.
                                                        </h4>



                                                        <h4>
                                                        Pulse modulation and sweep mode
                                                        </h4>

                                                    </div>

                                                    <div className='col-6'>
                                                        <p>Power coverage</p>
                                                        <h4>
                                                        -110 to +14dBm
                                                        </h4>



                                                         <p>Variety of common signal generating</p>
                                                        <h4>GSM | WCDMA | TDD-LTE | FDD-LTE NB-IoT | LoRa | 5GNR (Users can modify channels under different configuration)
                                                        </h4>

                                                        
                                                        <p>Simple control via USB port.</p>
                                                        <h4>API is provided for secondary development
                                                        </h4>

                                                    </div>

                                                </div>



                                            </div>

                                            <div className='col-md-12 mb-5'>
                                                <h2 className='mb-4'>Control Elements</h2>
                                                <div className='text-center mb-5'><img src='../Assets/vec-gen-2.webp' className='' style={{ width: '100%' }} /></div>

                                            </div>
                                            <div className='col-md-6'>
                                                <h3>Features</h3>
                                                <ul>
                                                    <li className='fs-5 mb-3'>
                                                    The BG100 is a compact and easy-to-setup device, making it portable and easy to deploy in various testing scenarios                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                    The BG100 supports a wide range of telecommunications and general modulation functions, offers three different product models, and has an open API interface for easy integration with other test equipment. This allows for high-end applications such as 5G large-scale antenna testing.

                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                    The BG100 offers an industry-leading dynamic range and sweep speed and comes with a wide range of features and functions included in the software, making it a cost-effective solution for signal generation.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                    The BG100 vector signal generator has a wide range of signal production capabilities. It can produce an arbitrary wave, continuous wave, common vector, digital, standard wireless vector, standard radio, and customized signals. This enables the BG100 to satisfy the majority of the signal simulation requirements of various users while also offering ongoing customization options.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                    The device has a large display and is easy to read, allowing users to easily navigate and control all of the device's features and functions.
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className='col-md-6'>
                                                <h3>Benefits</h3>
                                                <ul>
                                                    <li className='fs-5 mb-3'>
                                                    The BG100's compact size and easy-to-setup design allow for quick deployment in various testing scenarios, making it a versatile tool for field testing.
                                                    </li>
                                                    <li className='fs-5 mb-3'>
                                                    With its excellent system integration capabilities and secondary development options, the BG100 can be used for high-end applications such as 5G large-scale antenna testing.                                           </li>
                                                    <li className='fs-5 mb-3'>
                                                    Cost-Effective Solution: The BG100 offers an industry-leading dynamic range and sweep speed and comes with a wide range of features and functions included in the software, making it a cost-effective solution for signal generation.                           </li>
                                                    <li className='fs-5 mb-3'>
                                                    The BG100 can generate arbitrary wave signals, continuous wave signals, common vector signals, digital signals, standard wireless vector signals, standard radio signals, and customized signals, which makes it suitable for different types of signal generation.                                                  </li>
                                                    <li className='fs-5 mb-3'>
                                                    The BG100's user-friendly interface, large display, and ease of reading allow users to easily navigate and control all of the device's features and functions, making it easy to use and get accurate results.                                     </li>

                                                </ul>
                                            </div>


                                            <div className='px-4'>
                                                <div className='auto-img d-flex justify-content-center align-items-center'>
                                                    <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>



                                                </div>
                                            </div>
                                        </div>

                                    </div>



                                </div>}
                            </div>
                        </div>
                    </div>
                </div>






            </div>
            <Whatapp />
            <Footer />

        </>
    )
}

export default Microwave