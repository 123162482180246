import { useState } from 'react'
import Header from './Header'
import Nav from 'react-bootstrap/Nav';
import Footer from './Footer';

const Automatedtest = () => {
    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    }

    return (

        <>
            <Header />
            <div>
                <div className='container-fluid p-0'>
                    <div className='careers_bg2 p-4 me-auto'>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item "><a href="#" className='fs-5 '>Home</a></li>
                                <li class="breadcrumb-item fs-5">Automation Test Equipment</li>
                                {/* <li class="breadcrumb-item active" aria-current="page">Data</li> */}

                            </ol>
                        </nav>
                    </div>
                </div>


                {/* <div className='container py-5'>
      <Nav variant="pills" defaultActiveKey="/home">
      <Nav.Item>
        <Nav.Link href="#tab-1">Automation Test Equipment</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-1">Industrial Automation</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-2">Power Moniterning Solution</Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link eventKey="link-3">Test Benches & Lab Solutions</Nav.Link>
      </Nav.Item>
      <Nav.Item>
       
      </Nav.Item>
    </Nav>

      </div> */}
                <div className='container py-5'>
                    <div>
                        <ul className="nav  " variant="pills" >
                            <li className="nav-item">
                                <button className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`} onClick={() => handleTabClick('tab1')} style={{ border: 'none' }}>
                                    Automation Test Equipment
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`} onClick={() => handleTabClick('tab2')} style={{ border: 'none' }}>
                                    Industrial Automation
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`} onClick={() => handleTabClick('tab3')} style={{ border: 'none' }}>
                                    Power Moniterning Solution
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link ${activeTab === 'tab4' ? 'active' : ''}`} onClick={() => handleTabClick('tab4')} style={{ border: 'none' }}>
                                    Test Benches & Lab Solutions
                                </button>
                            </li>
                        </ul>

                        <div className="tab-content">
                            {activeTab === 'tab1' && <div className='border p-3 px-4'>

                                <h3 style={{ color: '#000080' }}>
                                    Automated Test Equipment
                                </h3>
                                <p className='fs-5'>
                                    We specialize in providing cutting-edge solutions for automated test of electronic, electrical, pneumatic, and thermal systems. Our team of experts is dedicated to crafting tailored solutions that precisely meet your unique requirements, ensuring the highest quality and efficiency in your testing processes. Whether you need a standard ATE or a custom-built system, we have the expertise and experience to deliver top-notch results.
                                </p>
                                <div className='row'>

                                    <div className='col-md-6'>
                                        <h4 style={{ color: '#000080', marginBottom: '20px' }}>
                                            Application Include

                                        </h4>
                                        <div>
                                            <ul>
                                                <li className='fs-5'>
                                                    Battery Test
                                                </li>
                                                <li className='fs-5'>
                                                    Monitering Test
                                                </li>

                                                <li className='fs-5'>
                                                    EVSE Testing
                                                </li>
                                                <li className='fs-5'>
                                                    Thermal Testing
                                                </li>
                                            </ul>


                                        </div>

                                    </div>

                                    <div className='col-md-6'>
                                        <h4 style={{ color: '#000080', marginBottom: '20px' }}>
                                            EVSE Testing

                                        </h4>
                                        <div className='mb-4'>
                                            <img src='../Assets/Automation-img-3.png' />

                                        </div>

                                    </div>
                                    <div className='px-4'>
                                        <div className='auto-img d-flex justify-content-center align-items-center'>
                                            <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><a href='/contactus' className='text-decoration-none text-white'>Talk with Us</a></button>



                                        </div>
                                    </div>
                                </div>



                            </div>

                            }


                            {activeTab === 'tab2' && <div className='border p-3'>

                                <h3 style={{ color: '#000080' }}>
                                    Industrial Automation
                                </h3>
                                <p className='fs-5'>
                                    Aarjay’s industrial automation solutions automate a variety of production processes, thereby improving productivity, quality, and safety. Applications range from automation of manual processes and individual machines to large integrated assembly lines. Frequently, there is a requirement for integration with SCADA or the cloud. Instrumentation and tools used in delivering our automation solutions include PLCs, sensors, motors, actuators, servo drives, and flow control valves (pneumatic/ hydraulic).
                                </p>

                                <div className='row'>

                                    <div className='col-md-6'>
                                        <h4 style={{ color: '#000080', marginBottom: '20px' }}>
                                            Solutions include

                                        </h4>
                                        <div>
                                            <ul>
                                                <li className='fs-5'>
                                                    PLC-based process automation & control
                                                </li>
                                                <li className='fs-5'>
                                                    Electrical LT/ HT/ Control Panels & Control Desks
                                                </li>

                                                <li className='fs-5'>
                                                    HMI/ SCADA solutions
                                                </li>
                                                <li className='fs-5'>
                                                    Cloud integration solutions
                                                </li>

                                                <li className='fs-5'>
                                                    Condition monitoring
                                                </li>

                                                <li className='fs-5'>
                                                    Energy management
                                                </li>
                                            </ul>


                                        </div>

                                    </div>

                                    <div className='col-md-6'>
                                        <h4 style={{ color: '#000080', marginBottom: '20px' }}>
                                            EVSE Testing

                                        </h4>
                                        <div className='mb-4'>
                                            <img src='../Assets/industry.png' />

                                        </div>

                                    </div>
                                    <div className='px-4'>
                                        <div className='auto-img d-flex justify-content-center align-items-center'>
                                            <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><a href='/contactus' className='text-decoration-none text-white'>Talk with Us</a></button>



                                        </div>
                                    </div>
                                </div>


                            </div>}
                            {activeTab === 'tab3' && <div className='border p-3'>

                                <h3 style={{ color: '#000080' }}>
                                    Power Monitoring Solutions
                                </h3>
                                <p className='fs-5'>
                                    The PQube® 3 series of high-speed, revenue-grade power analyzers measure, identify, and record all power quality disturbance, and environmental process parameter data in real-time.  It connects directly to voltages up to 690 V and auto-configures by auto-detecting the mains frequency, wiring configuration and nominal voltage. The PQube 3 series boasts an impressive number of standard features including intuitive touch-screen display, up to 14 energy metering channels, 4-quadrant metering, cloud connectivity, alarms, email alerts and automatic reporting. The PQube 3 is made sturdy and compact, easily fitting into production equipment, data centers, rackmounts or harsh environments.
                                </p>
                                <div className='row'>

                                    <div className='col-md-6'>
                                        <h4 style={{ color: '#000080', marginBottom: '20px' }}>
                                            Features:

                                        </h4>
                                        <div>
                                            <ul>
                                                <li className='fs-5'>
                                                    Synchronized AC&DC measurements
                                                </li>
                                                <li className='fs-5'>
                                                    Multiple 3-phase measurements with a single instrument
                                                </li>
                                                <li className='fs-5'>
                                                    Supra-harmonics (2-150kHz) and 4Mhz high-frequency measurements
                                                </li>
                                                <li className='fs-5'>
                                                    Certifications: UL, CSA, ROHS, FCC, IEC, ANSI, TUV, and EMC
                                                </li>
                                                <li className='fs-5'>
                                                    Supports multiple industry-standard communications protocols: Modbus/TCP, BACnet, SNMP, DNP3.0
                                                </li>
                                            </ul>

                                            <h4 style={{ color: '#000080', marginBottom: '20px' }}>
                                                Applications:

                                            </h4>

                                            <ul>
                                                <li className='fs-5'>
                                                    EV charger monitoring
                                                </li>
                                                <li className='fs-5'>
                                                    Micro-grid/ solar inverter monitoring
                                                </li>
                                                <li className='fs-5'>
                                                    Radar monitoring
                                                </li>
                                                <li className='fs-5'>
                                                    Railway/ Metrorail monitoring
                                                </li>
                                                <li className='fs-5'>
                                                    Transformer monitoring
                                                </li>

                                            </ul>
                                        </div>

                                    </div>

                                    <div className='col-md-6'>
                                        <h4 style={{ color: '#000080', marginBottom: '20px' }}>
                                            EVSE Testing

                                        </h4>
                                        <div className='mb-4'>
                                            <img src='../Assets/power.png' />

                                        </div>

                                    </div>
                                    <div className='px-4'>
                                        <div className='auto-img d-flex justify-content-center align-items-center'>
                                            <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><a href='/contactus' className='text-decoration-none text-white'>Talk with Us</a></button>



                                        </div>
                                    </div>
                                </div>

                            </div>}

                            {activeTab === 'tab4' && <div className='border p-3'>

                                <h3 style={{ color: '#000080' }}>
                                    Test Benches & Lab Solutions
                                </h3>
                                <p className='fs-5'>
                                    The PQube® 3 series of high-speed, revenue-grade power analyzers measure, identify, and record all power quality disturbance, and environmental process parameter data in real-time.  It connects directly to voltages up to 690 V and auto-configures by auto-detecting the mains frequency, wiring configuration and nominal voltage. The PQube 3 series boasts an impressive number of standard features including intuitive touch-screen display, up to 14 energy metering channels, 4-quadrant metering, cloud connectivity, alarms, email alerts and automatic reporting. The PQube 3 is made sturdy and compact, easily fitting into production equipment, data centers, rackmounts or harsh environments.
                                </p>

                                <div className='row'>

                                    <div className='col-md-6'>
                                        <h4 style={{ color: '#000080', marginBottom: '20px' }}>

                                            Integrated Systems With Multiple Test Benches

                                        </h4>
                                        <div>
                                            <ul>
                                                <li className='fs-5'>
                                                    ESD / Standard Workbenches
                                                </li>
                                                <li className='fs-5'>
                                                    Standard 19” cabinets
                                                </li>
                                                <li className='fs-5'>
                                                    Safety features (MCB, Em Stop, etc.)
                                                </li>
                                                <li className='fs-5'>
                                                    Internal Power Distribution
                                                </li>
                                                <li className='fs-5'>
                                                    T&M Instruments
                                                </li>
                                            </ul>




                                        </div>

                                    </div>

                                    <div className='col-md-6'>

                                        <h4 style={{ color: '#000080', marginBottom: '20px' }}>

                                            Calibration Benches
                                        </h4>
                                        <div>
                                            <ul>
                                                <li className='fs-5'>
                                                    Industrial work bench with modular consoles
                                                </li>
                                                <li className='fs-5'>
                                                    Ergonomic Workspace for calibration applications
                                                </li>
                                                <li className='fs-5'>
                                                    Power Supply & Power distribution
                                                </li>
                                                <li className='fs-5'>
                                                    Calibration Modules:

                                                </li>
                                                <li className='fs-5'>
                                                    T&M Instruments
                                                </li>
                                            </ul>




                                        </div>


                                    </div>
                                    <div className='px-5 mb-4'> <hr /> </div>
                                    <h4 style={{ color: '#000080', marginBottom: '20px', textAlign: 'center' }}>

                                        Wall Mount Solutions
                                    </h4>

                                    <div className='row'>

                                        <div className='col-md-6'>
                                            <h4 style={{ color: '#000080', marginBottom: '20px' }}>
                                                Features:

                                            </h4>
                                            <div>
                                                <ul>
                                                    <li className='fs-5'>
                                                        Synchronized AC&DC measurements
                                                    </li>
                                                    <li className='fs-5'>
                                                        Multiple 3-phase measurements with a single instrument
                                                    </li>
                                                    <li className='fs-5'>
                                                        Supra-harmonics (2-150kHz) and 4Mhz high-frequency measurements
                                                    </li>
                                                    <li className='fs-5'>
                                                        Certifications: UL, CSA, ROHS, FCC, IEC, ANSI, TUV, and EMC
                                                    </li>
                                                    <li className='fs-5'>
                                                        Supports multiple industry-standard communications protocols: Modbus/TCP, BACnet, SNMP, DNP3.0
                                                    </li>
                                                </ul>

                                                <h4 style={{ color: '#000080', marginBottom: '20px' }}>
                                                    Applications:

                                                </h4>

                                                <ul>
                                                    <li className='fs-5'>
                                                        EV charger monitoring
                                                    </li>
                                                    <li className='fs-5'>
                                                        Micro-grid/ solar inverter monitoring
                                                    </li>
                                                    <li className='fs-5'>
                                                        Radar monitoring
                                                    </li>
                                                    <li className='fs-5'>
                                                        Railway/ Metrorail monitoring
                                                    </li>
                                                    <li className='fs-5'>
                                                        Transformer monitoring
                                                    </li>

                                                </ul>
                                            </div>

                                        </div>

                                        <div className='col-md-6'>
                                            <h4 style={{ color: '#000080', marginBottom: '20px' }}>
                                               

                                            </h4>
                                            <div className='mb-4 text-center'>
                                                <img src='../Assets/wall-mount.png' />

                                            </div>

                                        </div>
                                        
                                    </div>

                                    <div className='px-4'>
                                        <div className='auto-img d-flex justify-content-center align-items-center'>
                                            <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><a href='/contactus' className='text-decoration-none text-white'>Talk with Us</a></button>



                                        </div>
                                    </div>
                                </div>

                            </div>}
                        </div>
                    </div>
                </div>






            </div>
            <Footer />
        </>
    )
}

export default Automatedtest