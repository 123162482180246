import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
    return (

        <>
            <div className='container-fluid py-3 pt-5' style={{ backgroundColor: '#1D262B',width:'100%' }}>
                <div className='container'>
                    <div className='row'>
                    <div className='col-md-5'>
                            <h4 className='text-white mb-4 text-start ms-5 mb-5'><span style={{ borderBottom: '3px solid #E85831', paddingBottom: '15px' }}>Information</span></h4>
                             <div className='d-flex ms-2'>
                                <div>
                                    <i class="fa fa-map-marker  me-3"   style={{ fontSize:'90px ' ,color:'#E85831'}} aria-hidden="true"></i>
                                </div>
                                <div className='text-start pe-2'>
                                    <h5 className='text-white'>Address</h5>
                                    <p className='text-white fs-5'>#006, Ground Floor, Sashank Aavaas,
                                        Ambabhavani Temple Road,Shamarajpura,Vidyaranyapura Post,
                                        Bangalore-560097,</p>
                                </div>

                            </div>

                            <div className='d-flex ms-2'>
                                <div>
                                    <i class="fa fa-envelope-o  me-3"   style={{ fontSize:'50px ' ,color:'#E85831'}} aria-hidden="true"></i>
                                </div>
                                <div className='text-start'>
                                    <h5 className='text-white'>Email</h5>
                                    <p style={{color:'#fff'}}  className='fs-5'>sales@ashtronixsolutions.com</p>
                                </div>

                            </div>

                            <div className='d-flex ms-2 mb-4'>
                                <div>
                                    <i class="fa fa-phone  me-3"   style={{ fontSize:'50px ' ,color:'#E85831'}} aria-hidden="true"></i>
                                </div>
                                <div className='text-start'>
                                    <h5 className='text-white'>Mobile</h5>
                                    <p style={{color:'#fff'}}  className='fs-5'> +91 80880 73675</p>
                                </div>
                                

                            </div>
                            <h5 className='text-start text-white mb-5'>Follows Us :
                                <span className='mx-2' style={{width:'70px',height:'70px', backgroundColor:'#3B5998',padding:'10px 14px', borderRadius:'50%'}}>
                                <a href='https://www.facebook.com/profile.php?id=61551509553884&mibextid=ZbWKwL' className='text-decoration-none text-white' target='_blank'> <i className='fa fa-facebook card1'></i></a>
                                    
                                    </span>
                                <span className='mx-2' style={{width:'70px',height:'70px', backgroundColor:'#3B77B5',padding:'10px 15px', borderRadius:'50%'}}>
                                    
                                <a href='https://www.linkedin.com/in/ashtronix-solutions-5a4a7828a' className='text-decoration-none text-white' target='_blank'><i className='fa fa-linkedin card1'></i></a>
                                    
                                    </span>
                                <span className='mx-2' style={{width:'70px',height:'70px', backgroundColor:'#52A1F3',padding:'10px 14px', borderRadius:'50%'}}><i className='fa fa-twitter card1'></i></span>
                                </h5>

                            <div className='d-flex ms-2'>
                                {/* <div>
                                    <i class="fa fa-globe  me-3"   style={{ fontSize:'50px ' ,color:'#E85831'}} aria-hidden="true"></i>
                                </div> */}
                                {/* <div className='text-start'>
                                    <h5 className='text-white'>Website</h5>
                                    <p style={{color:'#fff'}} className='fs-5'>www.ashtronixsolutions.com</p>
                                </div> */}

                            </div>
                        </div>
                        <div className='col-md-7'>

                        <div className='map'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3885.927427982521!2d77.54420887511014!3d13.103783911966406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s%23006%2C%20Ground%20Floor%2C%20Sashank%20Aavaas%2C%20Ambabhavani%20Temple%20Road%2CShamarajpura%2CVidyaranyapura%20Post%2C%20Bangalore-560097%2C!5e0!3m2!1sen!2sin!4v1698301026489!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                </div>
                        </div>
                        {/* <div className='col-md-5 text-center mb-4'>
                             <img src='../Assets/final-logo.jpg' className='mb-2 cmp-logo' style={{width:'350px',}}/>
                            <div>
                                <p className='text-white mb-4 fs-5 ' style={{textAlign:'justify'}}>
                                Ashtronix Solutions is a Bangalore (India) based company, we are pioneer in distribution and services of Testing & measuring instruments.
                                Our primary intent is to provide high quality & innovative products/technologies to our customers and support them with quality after sale services.
                                </p>
                            
                            </div>
                        </div> */}
                        {/* <div className='col-md-3 d-flex flex-column  align-items-center mb-4'>
                            <div><h4 className='text-white mb-4 ' style={{marginLeft: '-60px'}}><span style={{ borderBottom: '3px solid #E85831', paddingBottom: '15px' }}>Pages</span></h4></div>
                            <div>
                                <ul style={{ listStyle: 'none' }} className='flex-column  align-items-center justify-content-center'>
                                    <li>
                                        <Link to='/' className='nav-link text-white text-start'><i className='fa fa-check me-2' style={{ color: ' #E85831' }}></i>Home </Link>
                                    </li>
                                    <li>
                                        <Link to='/aboutus' className='nav-link text-white text-start'><i className='fa fa-check me-2' style={{ color: ' #E85831' }}></i>About Us </Link>
                                    </li>
                                    <li>
                                        <Link to='/' className='nav-link text-white text-start'><i className='fa fa-check me-2' style={{ color: ' #E85831' }}></i>Products & Solutions </Link>
                                    </li>
                                    <li>
                                        <Link to='/careers' className='nav-link text-white text-start'><i className='fa fa-check me-2' style={{ color: ' #E85831' }}></i>Careers </Link>
                                    </li>
                                    <li>
                                        <Link to='/contactus' className='nav-link text-white text-start'><i className='fa fa-check me-2' style={{ color: ' #E85831' }}></i>Contact Us </Link>
                                    </li>
                                </ul>
                            </div>
                        </div> */}


                     

                        
                    </div>

                    <hr className='text-white' />
                    <div>
                        <p className='text-center text-white fs-5'>
                        Copyright 2023 © All Right Reserved  by <a href='#' className='text-white'> Ashtronix Solutions</a>
                        </p>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Footer