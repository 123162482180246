import { useState } from 'react'
import Header from './Header'
import Nav from 'react-bootstrap/Nav';
import Footer from './Footer';
import Whatapp from '../cmp/whatapp'
import { Link } from 'react-router-dom';

const Powerelectronics = () => {

    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    }
    return (
        <>
            <Header />
            <div>
                <div className='container-fluid p-0'>
                    <div className='careers_bg2 p-4 me-auto'>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item "><a href="#" className='fs-5 '>Home</a></li>
                                <li class="breadcrumb-item fs-5">Power Electronics and Safety Analyser</li>
                                {/* <li class="breadcrumb-item active" aria-current="page">Data</li> */}

                            </ol>
                        </nav>
                    </div>
                </div>


                {/* <div className='container py-5'>
      <Nav variant="pills" defaultActiveKey="/home">
      <Nav.Item>
        <Nav.Link href="#tab-1">Automation Test Equipment</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-1">Industrial Automation</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-2">Power Moniterning Solution</Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link eventKey="link-3">Test Benches & Lab Solutions</Nav.Link>
      </Nav.Item>
      <Nav.Item>
       
      </Nav.Item>
    </Nav>

      </div> */}
                <div className='bg-light'>
                    <div className='container py-5' id='power-electronic'>
                        <div>
                            <ul className="nav" variant="pills" >
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`}  onClick={() => handleTabClick('tab1')} style={{ border: 'none' }}>
                                        Ac Power Supply
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`} onClick={() => handleTabClick('tab2')} style={{ border: 'none' }}>
                                        Dc Power Supply
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`} onClick={() => handleTabClick('tab3')} style={{ border: 'none' }}>
                                        Electrical Safety Analyser
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab4' ? 'active' : ''}`} onClick={() => handleTabClick('tab4')} style={{ border: 'none' }}>
                                        Power Analyser
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab5' ? 'active' : ''}`} onClick={() => handleTabClick('tab5')} style={{ border: 'none' }}>
                                        Moter Test Scheme
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab6' ? 'active' : ''}`} onClick={() => handleTabClick('tab6')} style={{ border: 'none' }}>
                                        Electronic Load
                                    </button>
                                </li>
                            </ul>

                            <div className="tab-content">
                                {activeTab === 'tab1' && <div className='border p-3 px-4'>



                                    <div className='row'>

                                        <div className='col-md-6'>

                                            <img src='../Assets/power-electronic.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-6'>
                                            <h3>
                                                Ac Testing power Supply
                                            </h3>
                                            <hr />
                                            <h5 className='fw-bold'>Product Model:</h5>
                                            <p className='fs-5'>
                                                AN61(F) Series
                                            </p>
                                            <h5 className='fw-bold'>Application</h5>
                                            <p className='fs-5'>
                                                applied for the labs and production lines in power electronics, avionics, IEC standard test and other industries.
                                            </p>
                                            <hr />
                                            <div className='mb-4'> <button className='btn btn-secondary py-2 fs-4 border-0' style={{ backgroundColor: '#e21108' }}>
                                                <a href='/contactus' className='text-decoration-none text-white'> More Info</a>
                                            </button> </div>

                                        </div>
                                        <div className='bg-white mb-4'>
                                            <ul className='ele-submenu'>
                                                <li className='fs-5 power-item'>
                                                    <a href='#prd-over' className='nav-power-items'>Product Overview</a>
                                                </li>


                                                <li className='fs-5  power-item'>
                                                    <a href='#prd-feat' className='nav-power-items'>Product Features</a>
                                                </li>
                                                <li className='fs-5  power-item'>
                                                    <a href='#prd-app' className='nav-power-items'>Product Application</a>
                                                </li>
                                                <li className='fs-5  power-item'>
                                                    <a href='#tech-speci' className='nav-power-items'>Technical Specifications</a>
                                                </li>


                                            </ul>
                                        </div>
                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-over'>Product Overview</h4>
                                            <hr />
                                            <p className='fs-5' style={{ textAlign: 'justify' }}>
                                                The AN61(F) series AC Power Supply adopts advanced SPWM technology,DSP digital processing technology and high-power switching power supply technology,can output AC, DC, AC+DC power and provide AC load, DC load and rectification load with precise power input. It has the ability of offering 6 times peak current and is optimal test instrument of measuring the surge current, and can be used to set the angle of waveform switch for testing the surge current and output holdup time. It can also be used to set the fluctuation ratio of voltage and frequency for scanning the range of power input to be tested.

                                                AN61(F) series product can simulate abnormal instantaneous rise, instantaneous drop, short circuit, jitter and others of electric supply, with the superposition function of harmonic waves or indirect harmonic waves, simulate the waveform distortion of electric supply, can also provide accurate and quick measurement of power parameters and harmonic waves, widely applied for the labs and production lines in power electronics, avionics, IEC standard test and other industries.

                                                AN61(F) series AC Power Supply possesses strong programmable function, can complete the immunity test of IEC61000-4-1(test before certification) /-4-13/-4-14/-4-28 standard. Also, its programmable output impedance, together with the power analyzer, can complete the test of IEC61000-3-2/-3-3 harmonic wave current limit and flicker and is optimal choice of IEC standard test.
                                            </p>

                                        </div>


                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-feat'>Product Features</h4>
                                            <hr />

                                            <ul>
                                                <li className='fs-5'>
                                                    Advanced SPWM, DSP and high-power switching power supply, high power density
                                                </li>
                                                <li className='fs-5'>
                                                    AC/DC/AC + DC output mode (only for AN615(F) series)
                                                </li>
                                                <li className='fs-5'>

                                                    l  Programmable output impedance for IEC61000-3-3 test
                                                </li>
                                                <li className='fs-5'>
                                                    IEC61000-4-11, IEC61000-4-14, IEC61000-4-28 voltage/frequency change test
                                                </li>
                                                <li className='fs-5'>
                                                    lEC61000-4-13 harmonic/sub-harmonic waveform synthesis test
                                                </li>
                                                <li className='fs-5'>
                                                    High output peak current for surge current test
                                                </li>
                                                <li className='fs-5'>
                                                    Pulse output for voltage dips test and simulation actual grid interference
                                                </li>
                                                <li className='fs-5'>
                                                    Step output. The Step test mode provides automatic switch to change the output voltage step by step instead of gradually
                                                </li>
                                                <li className='fs-5'>
                                                    List output, In sequence test mode, the output waveform is the combination of all serial number configuration. The user can edit the desired output voltage sequence on demand.
                                                </li>
                                                <li className='fs-5'>
                                                    Analog signal input interface for amplificaiton of external signal
                                                </li>
                                                <li className='fs-5'>
                                                    LCD display, compact, light, meeting requirements of standard cabinet installation
                                                </li>
                                                <li className='fs-5'>
                                                    Standard RS232 port, optional RS485, GPIB, Ethernet port.
                                                </li>
                                            </ul>

                                        </div>

                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-app'>Product Application</h4>
                                            <hr />

                                            <ul><li className='fw-bold fs-5'>Simulate Power Supply  input Interference</li></ul>
                                            <p className='fs-5'>Pulse, ladder, sequence and other output modes, simulating of any output wave in one step or continuously to simulate grid fluctuation.</p>
                                            <div className='row'>
                                                <div className='col-md-12 ' >
                                                    <img src='../Assets/simmulate-power-1.png' style={{ width: '100%' }} />
                                                </div>

                                            </div>

                                            <ul><li className='fw-bold fs-5'>Testing of switching power supply inrush current</li></ul>
                                            <p className='fs-5'> Free setting of start/stop angles through the output wave, peak current output up to 6 times, the AN61 series power supply is an ideal device for testing the inrush current of the switching power supply</p>
                                            <div className='row'>
                                                <div className='col-md-12 ' >
                                                    <img src='../Assets/test-switch.png' style={{ width: '100%' }} />
                                                </div>
                                            </div>

                                            <ul>
                                                <li className='fw-bold fs-5'>
                                                    Harmonic and inter-harmonic synthesis (only for 615XX series)
                                                </li>
                                            </ul>
                                            <p className='fs-5'>
                                                Superimposing of 2-40th harmonics and inter-harmonics, so as to conduct comprehensive harmonic simulation tests.
                                            </p>
                                            <div className='row'>
                                                <div className='col-md-12 ' >
                                                    <img src='../Assets/harmonic.png' style={{ width: '100%' }} />
                                                </div>
                                            </div>

                                            <ul>
                                                <li className='fw-bold fs-5'>
                                                    IEC Regulation test
                                                </li>
                                            </ul>
                                            <p className='fs-5'>
                                                Output of test voltage that meets IEC test conditions. Additionally, the software of host
                                                has a built-in test flow of IEC related test regulations to facilitate quick set up and use.
                                            </p>
                                            <div className='row'>
                                                <div className='col-md-12 ' >
                                                    <img src='../Assets/iec-reg.png' style={{ width: '100%' }} />
                                                </div>
                                            </div>

                                            <ul>
                                                <li className='fw-bold fs-5'>
                                                    Free wave amplification
                                                </li>
                                            </ul>
                                            <p className='fs-5'>
                                                The AN61 series AC test power supply can amplify power of any wave through the specific
                                                external port. The customer can record the actual wave on site using the wave recorder,
                                                and then send it to the specific external port of AN61 power supply via the wave generator
                                                for power amplification, so that the customer can simulate the actual wave on site to test
                                                the product under test.
                                            </p>
                                            <div className='row'>
                                                <div className='col-md-12 ' >
                                                    <img src='../Assets/free-wave.png' style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                            <ul>
                                                <li className='fw-bold fs-5'>
                                                    Control software of host
                                                </li>
                                            </ul>
                                            <div className='row'>
                                                <div className='col-md-12 ' >
                                                    <img src='../Assets/control-soft.png' style={{ width: '100%' }} />
                                                </div>
                                            </div>



                                        </div>
                                        <div>
                                            <h4 className='mb-3 text-start' id='tech-speci'>Technical Specification</h4>
                                            <hr />
                                            <div className='row mb-3'>
                                                <div className='col-md-12 ' >
                                                    <img src='../Assets/tech-speci.jpg' style={{ width: '100%' }} />
                                                </div>

                                            </div>

                                            <div className='row mb-3'>
                                                <div className='col-md-12 ' >
                                                    <img src='../Assets/tech-speci-1.jpg' style={{ width: '100%' }} />
                                                </div>

                                            </div>
                                            <div className='row mb-3'>
                                                <div className='col-md-12 ' >
                                                    <img src='../Assets/tech-speci-2.jpg' style={{ width: '100%' }} />
                                                </div>

                                            </div>
                                        </div>
                                        <div className='px-4'>
                                            <div className='auto-img d-flex justify-content-center align-items-center'>
                                                <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>



                                            </div>
                                        </div>
                                    </div>



                                </div>

                                }


                                {activeTab === 'tab2' && <div className='border p-3 bg-light'>

                                    <div className='row'>

                                        <div className='col-md-6'>

                                            <img src='../Assets/solar_power.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-6'>
                                            <h3>
                                                Solar Cell Simulator
                                            </h3>
                                            <hr />
                                            <h5 className='fw-bold'>Product Model:</h5>
                                            <p className='fs-5'>
                                                AN53S(F) Series
                                            </p>
                                            <h5 className='fw-bold'>Application</h5>
                                            <p className='fs-5'>
                                                New energy photovoltaic inverter, energy storage converter.
                                            </p>
                                            <hr />
                                            <div className='mb-4'> <button className='btn btn-secondary py-2 fs-4 border-0' style={{ backgroundColor: '#e21108' }}>

                                                <a href='/contactus' className='text-decoration-none text-white'> More Info</a>
                                            </button> </div>

                                        </div>
                                        <div className='bg-white mb-4'>
                                            <ul className='ele-submenu'>
                                                <li className='fs-5 power-item'>
                                                    <a href='#prd-over' className='nav-power-items'>Product Overview</a>
                                                </li>


                                                <li className='fs-5  power-item'>
                                                    <a href='#prd-feat' className='nav-power-items'>Product Features</a>
                                                </li>
                                                <li className='fs-5  power-item'>
                                                    <a href='#prd-app' className='nav-power-items'>Product Application</a>
                                                </li>
                                                <li className='fs-5  power-item'>
                                                    <a href='#tech-speci' className='nav-power-items'>Technical Specifications</a>
                                                </li>


                                            </ul>
                                        </div>
                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-over'>Product Overview</h4>
                                            <hr />
                                            <p className='fs-5' style={{ textAlign: 'justify' }}>
                                                AN53S(F) Series Solar Cell Simulator adopts active power factor correction technology and high-frequency
                                                LLC multi-resonant soft-switching inverter technology. It has high power factor, fast dynamic response,
                                                low output ripple and high power density. It has the characteristics of constant power and wide range
                                                output, as well as the advantages of small size, light weight, low noise, high efficiency and simple
                                                operation.
                                                The AN53S series extends the power output curve to provide users with a wider combination of voltage and current,
                                                which is more flexible than the traditional ' matrix ' output range power supply. Single constant power DC power
                                                supply output range may be several times the ordinary rectangular power supply. For example, the AN53 series of
                                                1500V / 40A / 15kW model can provide 1500V 10A output at 15kW power, or 375V 40A output. If it is a traditional
                                                ' matrix ' output power supply, its output index is 1500V / 10A / 15kW.When the output voltage is 375V, the
                                                maximum current is still 10A and the power is only 3.75kW.
                                            </p>

                                        </div>


                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-feat'>Product Features</h4>
                                            <hr />

                                            <ul>
                                                <li className='fs-5'>
                                                    Wide range output capability, the output range is expanded to 3 times of the same power level ' matrix ' power supply ;
                                                </li>
                                                <li className='fs-5'>
                                                    active power factor correction technology, full load power factor of more than 0.99 ;
                                                </li>
                                                <li className='fs-5'>

                                                    high frequency LLC multi resonant inverter, the whole machine efficiency is 0.95 ;
                                                </li>
                                                <li className='fs-5'>
                                                    the industry 's best transient response speed ;
                                                </li>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <img src='../Assets/solar-power-1.jpg' style={{ width: '50%' }} />
                                                    </div>
                                                </div>
                                                <li className='fs-5'>
                                                    Constant voltage, constant current, constant power three working modes to meet more test requirements ;
                                                </li>


                                                <li className='fs-5'>
                                                    powerful programmable function, flexible function setting ;
                                                </li>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <img src='../Assets/solar-power-2.jpg' style={{ width: '50%' }} />
                                                    </div>
                                                </div>
                                                <li className='fs-5'>
                                                    Photovoltaic simulation function ;
                                                </li>

                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <img src='../Assets/solar-power-3.jpg' style={{ width: '50%' }} />
                                                    </div>
                                                </div>

                                                <li className='fs-5'>
                                                    Battery simulation waveform editing
                                                </li>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <img src='../Assets/solar-power-4.jpg' style={{ width: '50%' }} />
                                                    </div>
                                                </div>
                                                <li className='fs-5'>
                                                    Built-in accurate voltage and current measurement, excellent output stability ;
                                                </li>
                                                <li className='fs-5'>
                                                    the lead voltage drop compensation terminal can realize the output lead voltage drop compensation when the large current works.
                                                </li>
                                                <li className='fs-5'>
                                                    Complete protection function to ensure the normal operation of power supply equipment and load safety ;
                                                </li>
                                                <li className='fs-5'>
                                                    high brightness color LCD display, beautiful appearance, simple and intuitive operation ;
                                                </li>
                                                <li className='fs-5'>
                                                    support multiple parallel output, expand the power / current range ;
                                                </li>
                                                <li className='fs-5'>
                                                    multiple machines can be flexibly set to single output or parallel output.
                                                </li>
                                            </ul>

                                        </div>

                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-app'>Product Application</h4>
                                            <hr />


                                            <p className='fs-5'>New energy photovoltaic inverter, energy storage converter.</p>

                                        </div>
                                        <div>
                                            <h4 className='mb-3 text-start' id='tech-speci'>Technical Specification</h4>
                                            <hr />
                                        </div>
                                        <div className='px-4'>
                                            <div className='auto-img d-flex justify-content-center align-items-center'>
                                            <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>



                                            </div>
                                        </div>
                                    </div>




                                </div>}
                                {activeTab === 'tab3' && <div className='border p-3'>

                                    <div className='row'>

                                        <div className='col-md-6'>

                                            <img src='../Assets/electrical_safety.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-6'>
                                            <h3>
                                                Efficient Electrical Safety Comprehensive Tester
                                            </h3>
                                            <hr />
                                            <h5 className='fw-bold'>Product Model:</h5>
                                            <p className='fs-5'>
                                                AN9640H(F)/AN9651H(F)/AN9651H-C(F)
                                            </p>
                                            <h5 className='fw-bold'>Application</h5>
                                            <p className='fs-5'>
                                                Household appliances, energy-saving lamps, medical equipment, information equipment, audio and video equipment, laboratory equipment, and charging piles.
                                            </p>
                                            <hr />
                                            <div className='mb-4'> <button className='btn btn-secondary py-2 fs-4 border-0' style={{ backgroundColor: '#e21108' }}>

                                                <a href='/contactus' className='text-decoration-none text-white'> More Info</a>
                                            </button> </div>

                                        </div>
                                        <div className='bg-white mb-4'>
                                            <ul className='ele-submenu'>
                                                <li className='fs-5 power-item'>
                                                    <a href='#prd-over' className='nav-power-items'>Product Overview</a>
                                                </li>


                                                <li className='fs-5  power-item'>
                                                    <a href='#prd-feat' className='nav-power-items'>Product Features</a>
                                                </li>
                                                <li className='fs-5  power-item'>
                                                    <a href='#prd-app' className='nav-power-items'>Product Application</a>
                                                </li>
                                                <li className='fs-5  power-item'>
                                                    <a href='#tech-speci' className='nav-power-items'>Technical Specifications</a>
                                                </li>


                                            </ul>
                                        </div>
                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-over'>Product Overview</h4>
                                            <hr />
                                            <p className='fs-5' style={{ textAlign: 'justify' }}>
                                                This Ainuo AN9640H(F)/AN9651H(F)/AN9651H-C(F) efficient electrical safety comprehensive tester
                                                features high speed, high accuracy and informatization, suitable for lines of intelligent manufacturing
                                                with fast-pace, hybrid, automation, informatization and MES management.
                                                In addition to seven-in-one testing, this series of products has rich optional functions for sub-sectors,
                                                including automatic multi-stage power judgment, smart lamp switching test, LN phase sequence detection,
                                                LN detection, 64A ground bond resistance test, 500W/6kW/10kW testing power supply, U.S. standard testing
                                                power supply, LC and PW uninterruptible power supply test, etc.
                                                This series of products can meet the safety standard testing requirements of various electrical products such
                                                as household appliances, energy-saving lamps, medical equipment, information equipment, audio and video
                                                equipment, laboratory equipment, and charging piles.
                                                PC ESRS system, with barcode scanning interface to automatically identify the product barcode and specifications, automatically
                                                call the test program, scan the code and start automatically, and pack and store the test data.
                                                PC ESRS system, with local storage of test data, data query, statistics and export; real-time transmission of test data, active upload, query upload, breakpoint resume,
                                                etc.; downloading test programs from the server.
                                                Optional connection with MES through WIFI, LAN, RS232 and other interfaces, connection with intermediate table of SQLServer, Oracle, MySQL and other database, Modbus TCP connection, Web Api interface for connection,
                                                as well as connection with local TXT and Access database.
                                            </p>

                                        </div>


                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-feat'>Product Features</h4>
                                            <hr />
                                            <p className='fw-bold'>High reliability</p>
                                            <ul>
                                                <li className='fs-5'>
                                                    13 draft units who participated in drafting of national standards and verification regulations for safety comprehensive tester;
                                                </li>
                                                <li className='fs-5'>
                                                    30 years of experience on safety testing expertise and understanding of customer needs;
                                                </li>
                                                <li className='fs-5'>

                                                    Strict electromagnetic environment, load conditions, endurance test verification;
                                                </li>
                                                <li className='fs-5'>
                                                    Electric shock protection, arc detection, open circuit detection, slow rise and down;
                                                </li>
                                                <li>
                                                    1% accuracy for safety compliance analyzer, 0.2% accuracy for power.
                                                </li>
                                            </ul>
                                            <p className='fw-bold'>High Speed</p>
                                            <ul>
                                                <li className='fs-5'>
                                                    Fast measurement and control: 0.5s step fast test, self-starting of first item after GB;
                                                </li>
                                                <li className='fs-5'>
                                                    Parallel test: GB and ACW/DCW/IR in parallel, time-saving.
                                                </li>
                                            </ul>
                                            <p className='fw-bold'>Intelligent</p>
                                            <ul>
                                                <li className='fs-5'>
                                                    Intelligent system: PC ESRS system;
                                                </li>
                                                <li className='fs-5'>
                                                    Barcode management: barcode scanning and recognition, program matching, scan to start;
                                                </li>
                                                <li className='fs-5'>
                                                    Data management: test data storage, query, export, program file copy;
                                                </li>
                                                <li className='fs-5'>
                                                    Connection with MES: LAN/WIFI interface, MES connection for various database.
                                                </li>

                                            </ul>
                                        </div>

                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-app'>Product Application</h4>
                                            <hr />


                                            <p className='fs-5'>
                                                This series of products can meet the safety standard testing requirements of various electrical products such as household appliances,
                                                energy-saving lamps,
                                                medical equipment, information equipment, audio and video equipment, laboratory equipment, and charging piles.
                                            </p>

                                        </div>
                                        <div>
                                            <h4 className='mb-3 text-start' id='tech-speci'>Technical Specification</h4>
                                            <hr />
                                            <div className='row mb-4'>
                                                <div className='col-md-12'>

                                                    <img src='../Assets/electrical_speci.png' style={{ width: '100%' }} />

                                                </div>
                                            </div>

                                        </div>
                                        <div className='px-4'>
                                            <div className='auto-img d-flex justify-content-center align-items-center'>
                                            <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>
                                            </div>
                                        </div>
                                    </div>




                                </div>}

                                {activeTab === 'tab4' && <div className='border p-3'>

                                    <div className='row'>

                                        <div className='col-md-6'>

                                            <img src='../Assets/power_analy.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-6'>
                                            <h3>
                                                High-accuracy Three-phase Power Meter
                                            </h3>
                                            <hr />
                                            <h5 className='fw-bold'>Product Model:</h5>
                                            <p className='fs-5'>
                                                AN87330(F) Series
                                            </p>
                                            <h5 className='fw-bold'>Application</h5>
                                            <p className='fs-5'>
                                                Test requirements for three-phase equipment in motor, home appliance, new energy and other fields
                                            </p>
                                            <hr />
                                            <div className='mb-4'> <button className='btn btn-secondary py-2 fs-4 border-0' style={{ backgroundColor: '#e21108' }}>

                                                <a href='/contactus' className='text-decoration-none text-white'> More Info</a>
                                            </button> </div>

                                        </div>
                                        <div className='bg-white mb-4'>
                                            <ul className='ele-submenu'>
                                                <li className='fs-5 power-item'>
                                                    <a href='#prd-over' className='nav-power-items'>Product Overview</a>
                                                </li>


                                                <li className='fs-5  power-item'>
                                                    <a href='#prd-feat' className='nav-power-items'>Product Features</a>
                                                </li>
                                                <li className='fs-5  power-item'>
                                                    <a href='#prd-app' className='nav-power-items'>Product Application</a>
                                                </li>
                                                <li className='fs-5  power-item'>
                                                    <a href='#tech-speci' className='nav-power-items'>Technical Specifications</a>
                                                </li>


                                            </ul>
                                        </div>
                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-over'>Product Overview</h4>
                                            <hr />
                                            <p className='fs-5' style={{ textAlign: 'justify' }}>
                                                AN87330 series high-precision power analyzer, using the latest FPGA + ARM digital processing system,
                                                to achieve full synchronization sampling, fully meet the market demand for testing three-phase equipment
                                                in the field of motor, household appliances, new energy, etc.
                                            </p>

                                        </div>


                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-feat'>Product Features</h4>
                                            <hr />

                                            <ul>
                                                <li className='fs-5'>
                                                    Test bandwidth: DC, 0.5~100kHz, 0.1%, 0.15~1000V, 0.2m~50A (including custom range)
                                                </li>
                                                <li className='fs-5'>
                                                    harmonics, external sensor input
                                                </li>
                                                <li className='fs-5'>
                                                    Standard LAN port, RS232/485 port, MODBUS-RTU, TCP-MODBUS and custom protocols.

                                                </li>
                                                <li className='fs-5'>
                                                    Optional motor board, compatible with electro-mechanical power synchronization efficiency test.
                                                </li>

                                            </ul>




                                        </div>

                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-app'>Product Application</h4>
                                            <hr />


                                            <p className='fs-5'>
                                                This series of products can meet the safety standard testing requirements of various electrical products such as household appliances,
                                                energy-saving lamps,
                                                medical equipment, information equipment, audio and video equipment, laboratory equipment, and charging piles.
                                            </p>

                                        </div>
                                        <div>
                                            <h4 className='mb-3 text-start' id='tech-speci'>Technical Specification</h4>
                                            <hr />
                                            <div className='row mb-4'>
                                                <div className='col-md-12'>

                                                    <img src='../Assets/electrical_speci.png' style={{ width: '100%' }} />

                                                </div>
                                            </div>

                                        </div>
                                        <div className='px-4'>
                                            <div className='auto-img d-flex justify-content-center align-items-center'>
                                            <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>



                                            </div>
                                        </div>
                                    </div>



                                </div>}

                                {activeTab === 'tab5' && <div className='border p-3'>

                                    <div className='row'>

                                        <div className='col-md-6'>

                                            <img src='../Assets/high_power_motor.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-6'>
                                            <h3>
                                            Comprehensive High-power Motor Test Scheme  
                                            </h3>
                                            <hr />
                                            <h5 className='fw-bold'>Product Model:</h5>
                                            <p className='fs-5'>
                                            AN8353(F) Series
                                            </p>
                                            <h5 className='fw-bold'>Application</h5>
                                            <p className='fs-5'>
                                            used in industries such as elevator motor, reducer, industrial fan, water pump motor, crane, generator and so on.
                                            </p>
                                            <hr />
                                            <div className='mb-4'> <button className='btn btn-secondary py-2 fs-4 border-0' style={{ backgroundColor: '#e21108' }}>

                                                <a href='/contactus' className='text-decoration-none text-white'> More Info</a>
                                            </button> </div>

                                        </div>
                                        <div className='bg-white mb-4'>
                                            <ul className='ele-submenu'>
                                                <li className='fs-5 power-item'>
                                                    <a href='#prd-over' className='nav-power-items'>Product Overview</a>
                                                </li>


                                                <li className='fs-5  power-item'>
                                                    <a href='#prd-feat' className='nav-power-items'>Product Features</a>
                                                </li>
                                                <li className='fs-5  power-item'>
                                                    <a href='#prd-app' className='nav-power-items'>Product Application</a>
                                                </li>
                                                <li className='fs-5  power-item'>
                                                    <a href='#tech-speci' className='nav-power-items'>Technical Specifications</a>
                                                </li>


                                            </ul>
                                        </div>
                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-over'>Product Overview</h4>
                                            <hr />
                                            <p className='fs-5' style={{ textAlign: 'justify' }}>
                                            AN8353S(F)/AN8353M(F) series comprehensive high-power motor test scheme&nbsp;is suitable for the performance test of 3-phase stator/complete machine with power below 500KW, and widely used in industries such as elevator motor, reducer, industrial fan, water pump motor, crane, generator and so on.
                                            </p>

                                        </div>


                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-feat'>Product Features</h4>
                                            <hr />

                                            <ul>
                                                <li className='fs-5'>
                                                Wide range: test of ultra wide resistance range (1mΩ~400KΩ).
                                                </li>
                                                <li className='fs-5'>
                                                High level: 500KW motor turn-to-turn vibration, improving the detection rate of winding damage.
                                                </li>
                                                <li className='fs-5'>
                                                Efficient: complete testing after connection at one time, high efficiency of large motor test.

                                                </li>
                                                <li className='fs-5'>
                                                One-stop: self-developed AC/DC power supply, convenient and unified before/after sale.
                                                </li>
                                                <li className='fs-5'>
                                                Module: Module test unit to avoid interference, and customize single/multi-station, serial/parallel test system as required.
                                                </li>
                                                <li className='fs-5'>
                                                Multi ports: USB, RS232, LAN and other ports, PLC simulation, TCP/IP network protocol, etc., to facilitate data interaction and instrument control.
                                                </li>
                                               

                                            </ul>




                                        </div>

                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-app'>Product Application</h4>
                                            <hr />

                                              <button className='fw-bold border-0 px-3 py-2'>Test Items</button>
                                            <p className='fs-5'>
                                            Stator: withstand voltage, insulation, resistance, resistance balance, interturn<br />
                                            Motor: withstand voltage, insulation, resistance, interturn, no-load, locked-rotor

                                            </p>
                                            <button className='fw-bold border-0 px-3 py-2'>Test Cases</button>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                  <img src='../Assets/test-case.png'  style={{width:'70%'}}/>
                                                    </div>

                                                </div>

                                        </div>
                                        <div>
                                            <h4 className='mb-3 text-start' id='tech-speci'>Technical Specification</h4>
                                            <hr />
                                            <div className='row mb-4'>
                                                <div className='col-md-12'>

                                                    <img src='../Assets/power-speci.png' style={{ width: '100%' }} />

                                                </div>
                                            </div>

                                        </div>
                                        <div className='px-4'>
                                            <div className='auto-img d-flex justify-content-center align-items-center'>
                                            <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>



                                            </div>
                                        </div>
                                    </div>



                                </div>}

                                
                                {activeTab === 'tab6' && <div className='border p-3'>

                                    <div className='row'>

                                        <div className='col-md-6'>

                                            <img src='../Assets/dc-electric.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-6'>
                                            <h3>
                                            DC Electronic Load 
                                            </h3>
                                            <hr />
                                            <h5 className='fw-bold'>Product Model:</h5>
                                            <p className='fs-5'>
                                            AN23500(F) Series 
                                            </p>
                                            <h5 className='fw-bold'>Application</h5>
                                            <p className='fs-5'>
                                            Testing of products in many fields such as new energy vehicle OBC, power battery, charging spot test, power electronics, servo/server power supply, high voltage UPS, photovoltaic, grid energy storage, aerospace and other fields. 
                                            </p>
                                            <hr />
                                            <div className='mb-4'> <button className='btn btn-secondary py-2 fs-4 border-0' style={{ backgroundColor: '#e21108' }}>

                                                <a href='/contactus' className='text-decoration-none text-white'> More Info</a>
                                            </button> </div>

                                        </div>
                                        <div className='bg-white mb-4'>
                                            <ul className='ele-submenu'>
                                                <li className='fs-5 power-item'>
                                                    <a href='#prd-over' className='nav-power-items'>Product Overview</a>
                                                </li>


                                                <li className='fs-5  power-item'>
                                                    <a href='#prd-feat' className='nav-power-items'>Product Features</a>
                                                </li>
                                                <li className='fs-5  power-item'>
                                                    <a href='#prd-app' className='nav-power-items'>Product Application</a>
                                                </li>
                                                <li className='fs-5  power-item'>
                                                    <a href='#tech-speci' className='nav-power-items'>Technical Specifications</a>
                                                </li>


                                            </ul>
                                        </div>
                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-over'>Product Overview</h4>
                                            <hr />
                                            <p className='fs-5' style={{ textAlign: 'justify' }}>
                                            The AN23500(F) series DC electronic load is a new DC load launched by Ainuo Instruments, covering voltage of 150V and 600V,
                                             and the power 150W~1.2kW. This series of electronic load is mainly used for testing of products in many fields such as new
                                              energy vehicle OBC, power battery, charging spot test, power electronics, servo/server power supply, high voltage UPS, photovoltaic, 
                                              grid energy storage, aerospace and other fields. A new generation of digital controller is adopted, with built-in five modes and a variety
                                               of advanced modes, to meet the programming, automated testing and other needs.
                                            </p>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                  <img src='../Assets/dc-table.png'  style={{width:'70%'}}/>
                                                    </div>

                                                </div>

                                        </div>


                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-feat'>Product Features</h4>
                                            <hr />

                                            <ul>
                                                <li className='fs-5'>
                                                Precision measurement, with accuracy of voltage 0.015%+0.03%F.S., current 0.03%+0.05%F.S. and power 0.1%+0.1%F.S.;
                                                </li>
                                                <li className='fs-5'>
                                                Built-in dynamic loading mode, up to 25kHz dynamic frequency, Vpk+/- test;
                                                </li>
                                                <li className='fs-5'>
                                              
                                                Built-in LED to simulate LED load and test LED power supply;
                                                </li>
                                                <li className='fs-5'>
                                                Minor dynamic overshoot amplitude, less than 30% of set current;
                                                </li>
                                                <li className='fs-5'>
                                                Built-in constant current (CC), constant voltage (CV), constant resistance (CR), constant power (CP), short circuit simulation, overcurrent protection test, series test, AUTO test etc.;
                                                </li>
                                                <li className='fs-5'>
                                                Complete protection, including OCP (overcurrent protection)/OVP (overvoltage protection)/ OTP (overtemperature protection)/RVP (reverse connection protection), etc.;
                                                </li>
                                                <li className='fs-5'>
                                                Built-in temperature acquisition chip and speed-adjustable fan control;
                                                </li>
                                                <li className='fs-5'>
                                                Built-in battery, suitable for discharge test for power integration;
                                                </li>
                                                <li className='fs-5'>
                                                Flexible interface, standard RS232, optional RS485;
                                                </li>
                                               

                                            </ul>




                                        </div>

                                        <div>
                                            <h4 className='mb-3 text-start' id='prd-app'>Product Application</h4>
                                            <hr />
                                               
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                  <img src='../Assets/dc-com.png'  style={{width:'70%'}}/>
                                                    </div>

                                                </div>
                                                <ul>
                                                    <li className='fs-5'>
                                                    Switch power supply test
                                                    </li>
                                                    <li>
                                                    Server power supply and communication power supply test 
                                                    </li>
                                                    <li>
                                                    Adapter, charger and charge bank test
                                                    </li>
                                                    <li>
                                                    Battery discharge test
                                                    </li>
                                                    <li>
                                                    Automotive electronics test, such as fuses, control boxes, etc.
                                                    </li>
                                                    <li>
                                                    Relay simulation load test
                                                    </li>
                                                    <li>
                                                    Aerospace power test
                                                    </li>
                                                    <li>
                                                    DC power supply and power electronic components test
                                                    </li>
                                                </ul>
                                             <button className='fs-5 border-0 mb-3'>Basic Modes</button>
                                             <p>
                                             The AN23500(F) load has four built-in basic modes: constant voltage (CV), constant current (CC), fixed-point group, and constant power (CP), suitable for various testing.
                                             </p>
                                             <div className='row mb-4'>
                                                <div className='col-md-12'>

                                                    <img src='../Assets/graph.jpg' style={{ width: '50%' }} />

                                                </div>
                                            </div>

                                            <button className='fs-5 border-0 mb-3'>Combined Modes</button>
                                             <p>
                                             The AN23500(F)&nbsp;load has four built-in combined modes: soft-start, loading/unloading, CC+CV, CC+CV,&nbsp;suitable for various testing.
                                             </p>
                                             <div className='row mb-4'>
                                                <div className='col-md-12'>

                                                    <img src='../Assets/graph-1.jpg' style={{ width: '50%' }} />

                                                </div>
                                            </div>
                                           
                                           
                                            

                                        </div>
                                        <div>
                                            <h4 className='mb-3 text-start' id='tech-speci'>Technical Specification</h4>
                                            <hr />
                                            <div className='row mb-4'>
                                                <div className='col-md-12'>

                                                    <img src='../Assets/table-electronic.png' style={{ width: '100%' }} />

                                                </div>
                                            </div>

                                        </div>
                                        <div className='px-4'>
                                            <div className='auto-img d-flex justify-content-center align-items-center'>
                                            <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>



                                            </div>
                                        </div>
                                    </div>



                                </div>}
                            </div>
                        </div>
                    </div>
                </div>






            </div>
            <Whatapp />
            <Footer />

        </>
    )
}

export default Powerelectronics