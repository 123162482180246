import React from 'react'
import { useState } from 'react'
import Header from './Header'
import Nav from 'react-bootstrap/Nav';
import Footer from './Footer';
import Whatapp from '../cmp/whatapp'
import { Link } from 'react-router-dom';
const Generalpurpose = () => {

    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    }
    return (

        <>
            <Header />
            <div>
                <div className='container-fluid p-0'>
                    <div className='careers_bg2 p-4 me-auto'>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item "><a href="#" className='fs-5 '>Home</a></li>
                                <li class="breadcrumb-item fs-5">General Purpose T&M Equipments</li>
                                {/* <li class="breadcrumb-item active" aria-current="page">Data</li> */}

                            </ol>
                        </nav>
                    </div>
                </div>


                {/* <div className='container py-5'>
      <Nav variant="pills" defaultActiveKey="/home">
      <Nav.Item>
        <Nav.Link href="#tab-1">Automation Test Equipment</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-1">Industrial Automation</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-2">Power Moniterning Solution</Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link eventKey="link-3">Test Benches & Lab Solutions</Nav.Link>
      </Nav.Item>
      <Nav.Item>
       
      </Nav.Item>
    </Nav>

      </div> */}
                <div className='bg-light'>
                    <div className='container py-5'>
                        <div>
                            <ul className="nav" variant="pills" >
                                <li className="nav-item">
                                    <button className={`nav-link mb-3 ${activeTab === 'tab1' ? 'active' : ''}`} onClick={() => handleTabClick('tab1')} style={{ border: 'none' }}>
                                        Digital Oscilloscopes
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`} onClick={() => handleTabClick('tab2')} style={{ border: 'none' }}>
                                        Waveform Generators
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`} onClick={() => handleTabClick('tab3')} style={{ border: 'none' }}>
                                        Digital Multimeters
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab4' ? 'active' : ''}`} onClick={() => handleTabClick('tab4')} style={{ border: 'none' }}>
                                        Power Supplies
                                    </button>
                                </li>

                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab5' ? 'active' : ''}`} onClick={() => handleTabClick('tab5')} style={{ border: 'none' }}>
                                        Spectrum Analyzers
                                    </button>
                                </li>

                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab6' ? 'active' : ''}`} onClick={() => handleTabClick('tab6')} style={{ border: 'none' }}>
                                        Thermal Imagers
                                    </button>
                                </li>

                                <li className="nav-item">
                                    <button className={`nav-link mb-3 ${activeTab === 'tab7' ? 'active' : ''}`} onClick={() => handleTabClick('tab7')} style={{ border: 'none' }}>
                                        Anenmo Meter
                                    </button>
                                </li>
                            </ul>

                            <div className="tab-content">
                                {activeTab === 'tab1' && <div className='border p-3 px-4'>

                                    <div>
                                        <h2>Digital Oscilloscopes</h2>
                                        <p className='fs-5'>
                                            We are one of the leading and professional digital oscilloscopes manufacturers and suppliers .
                                            Thanks to our highly qualified experts and advanced technology, we are also one of the world's
                                            leading brands. Welcome to buy the quality, cheap and durable dso, oscope, digital storage oscilloscope,
                                            oscilloscope probe, scope probe in stock from us. Check the quotation with us now.
                                        </p>
                                        <hr />
                                    </div>

                                    <div className='row'>

                                        <div className='col-md-3'>

                                            <img src='../Assets/osill-1.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9'>
                                            <h4>OWON FDS1000 Series Multifunction DS</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model</th>
                                                            <th>Bandwidth</th>
                                                            <th>Sample Rate</th>
                                                            <th>Vertical Resolution</th>
                                                            <th>Record Length</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>FDS1102A</td>
                                                            <td>100MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>Max 14 bits</td>
                                                            <td>10M</td>
                                                        </tr>
                                                        <tr>
                                                            <td>FDS1102</td>
                                                            <td>100MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>8 bits</td>
                                                            <td>10M</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/osill-2.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9'>
                                            <h4>OWON XDS4000 Series 350MHz-500MHz Digital Oscilloscope</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No.</th>
                                                            <th>Bandwidth</th>
                                                            <th>Sample Rate</th>
                                                            <th>Channel</th>
                                                            <th>Record Length</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>XDS4352</td>
                                                            <td>350MHz</td>
                                                            <td>5GSa/s</td>
                                                            <td>2</td>
                                                            <td>400M</td>
                                                        </tr>
                                                        <tr>
                                                            <td>XDS4354</td>
                                                            <td>350MHz</td>
                                                            <td>5GSa/s</td>
                                                            <td>4</td>
                                                            <td>400M</td>
                                                        </tr>
                                                        <tr>
                                                            <td>XDS4502</td>
                                                            <td>500MHz</td>
                                                            <td>5GSa/s</td>
                                                            <td>2</td>
                                                            <td>400M</td>
                                                        </tr>
                                                        <tr>
                                                            <td>XDS4504</td>
                                                            <td>500MHz</td>
                                                            <td>5GSa/s</td>
                                                            <td>4</td>
                                                            <td>400M</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/osill-3.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mb-3'>
                                            <h4>OWON XDS3000 Series n-in-1 Digital Oscilloscope</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model</th>
                                                            <th>Bandwidth</th>
                                                            <th>Sample Rate</th>
                                                            <th>Vertical Resolution (A/D)</th>
                                                            <th>Record Length</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>XDS3202A</td>
                                                            <td>200MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>14 bits</td>
                                                            <td>40M</td>
                                                        </tr>
                                                        <tr>
                                                            <td>XDS3102A</td>
                                                            <td>100MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>12 bits</td>
                                                            <td>40M</td>
                                                        </tr>
                                                        <tr>
                                                            <td>XDS3062A</td>
                                                            <td>60MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>12 bits</td>
                                                            <td>40M</td>
                                                        </tr>
                                                        <tr>
                                                            <td>XDS3102</td>
                                                            <td>100MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>8 bits</td>
                                                            <td>40M</td>
                                                        </tr>
                                                        <tr>
                                                            <td>XDS3202E</td>
                                                            <td>200MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>8 bits</td>
                                                            <td>40M</td>
                                                        </tr>
                                                        <tr>
                                                            <td>XDS3202</td>
                                                            <td>200MHz</td>
                                                            <td>2GS/s</td>
                                                            <td>8 bits</td>
                                                            <td>40M</td>
                                                        </tr>
                                                        <tr>
                                                            <td>XDS3302</td>
                                                            <td>300MHz</td>
                                                            <td>2.5GS/s</td>
                                                            <td>8 bits</td>
                                                            <td>40M</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/osill-4.jpg' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mb-5'>
                                            <h4>OWON VDS6000 series 2CH PC Oscilloscope</h4>
                                            <div class="table-responsive mb-4">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No.</th>
                                                            <th>Bandwidth</th>
                                                            <th>Sample Rate</th>
                                                            <th>secondary development</th>
                                                            <th>Record Length</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>VDS6102	</td>
                                                            <td>100MHz</td>
                                                            <td>1GSa/s</td>
                                                            <td>windows/android/MAC OS/Linux</td>
                                                            <td>10M</td>
                                                        </tr>
                                                        <tr>
                                                            <td>VDS6102A</td>
                                                            <td>100MHz</td>
                                                            <td>1GSa/s</td>
                                                            <td>windows/android/MAC OS/Linux</td>
                                                            <td>10M</td>
                                                        </tr>



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/osill-5.jpg' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9'>
                                            <h4>TAO3000 series 2CH 8/14bit tablet Oscilloscope</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No.</th>
                                                            <th>Bandwidth</th>
                                                            <th>Sample Rate</th>
                                                            <th>Vertical Resolution (A/D)</th>
                                                            <th>Record Length</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>TAO3072</td>
                                                            <td>70MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>8 bits</td>
                                                            <td>40M</td>
                                                        </tr>
                                                        <tr>
                                                            <td>TAO3102</td>
                                                            <td>100MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>8 bits</td>
                                                            <td>40M</td>
                                                        </tr>
                                                        <tr>
                                                            <td>TAO3122</td>
                                                            <td>120MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>8 bits</td>
                                                            <td>40M</td>
                                                        </tr>
                                                        <tr>
                                                            <td>XDS3102</td>
                                                            <td>100MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>8 bits</td>
                                                            <td>40M</td>
                                                        </tr>
                                                        <tr>
                                                            <td>TAO3072A</td>
                                                            <td>70MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>8bits/12bits/14bits</td>
                                                            <td>40M</td>
                                                        </tr>
                                                        <tr>
                                                            <td>TAO3102A</td>
                                                            <td>100MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>8bits/12bits/14bits</td>
                                                            <td>40M</td>
                                                        </tr>
                                                        <tr>
                                                            <td>TAO3122A</td>
                                                            <td>120MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>8bits/12bits/14bits</td>
                                                            <td>40M</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/osill-6.jpg' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9'>
                                            <h4>OWON VDS6000 series 4CH PC Oscilloscope</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No.</th>
                                                            <th>Bandwidth</th>
                                                            <th>Sample Rate</th>
                                                            <th>Vertical accurate</th>
                                                            <th>Secondary</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>VDS6074	</td>
                                                            <td>70MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>8 bits</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>VDS6104</td>
                                                            <td>100MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>8 bits</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>VDS6074A</td>
                                                            <td>70MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>8bits/12bits/14bits</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>VDS6104A</td>
                                                            <td>100MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>8bits/12bits/14bits</td>
                                                            <td></td>
                                                        </tr>




                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/osill-7.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9'>
                                            <h4>OWON VDS Series PC Oscilloscope</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No.</th>
                                                            <th>Bandwidth</th>
                                                            <th>Sample Rate</th>
                                                            <th>Channel</th>
                                                            <th>Extra Features</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>VDS1022I</td>
                                                            <td>25MHz</td>
                                                            <td>100MS/s</td>
                                                            <td>2</td>
                                                            <td>USB isolation</td>
                                                        </tr>
                                                        <tr>
                                                            <td>VDS1022</td>
                                                            <td>25MHz</td>
                                                            <td>100MS/s	</td>
                                                            <td>2</td>
                                                            <td>/</td>
                                                        </tr>
                                                        <tr>
                                                            <td>VDS2062</td>
                                                            <td>60MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>2</td>
                                                            <td>/</td>
                                                        </tr>
                                                        <tr>
                                                            <td>VDS2064</td>
                                                            <td>60MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>4</td>
                                                            <td>/</td>
                                                        </tr>
                                                        <tr>
                                                            <td>VDS3102</td>
                                                            <td>100MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>2s</td>
                                                            <td>/</td>
                                                        </tr>
                                                        <tr>
                                                            <td>VDS3104</td>
                                                            <td>100MHz</td>
                                                            <td>1GS/s</td>
                                                            <td>4</td>
                                                            <td>/</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/osill-8.jpg' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9'>
                                            <h4>OWON HDS200 Series Digital Oscilloscope</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No.</th>
                                                            <th>Bandwidth</th>
                                                            <th>Sample Rate</th>
                                                            <th>Multimeter</th>
                                                            <th>Signal Generator</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>HDS242</td>
                                                            <td>40MHz</td>
                                                            <td>250MSa/s</td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>HDS242S</td>
                                                            <td>40MHz</td>
                                                            <td>250MSa/s	</td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>HDS272</td>
                                                            <td>70MHz</td>
                                                            <td>250MSa/s</td>
                                                            <td></td>
                                                            <td>/</td>
                                                        </tr>
                                                        <tr>
                                                            <td>HDS272S</td>
                                                            <td>70MHz</td>
                                                            <td>250MSa/s</td>
                                                            <td></td>
                                                            <td>/</td>
                                                        </tr>
                                                        <tr>
                                                            <td>HDS2102</td>
                                                            <td>100MHz</td>
                                                            <td>500MSa/s</td>
                                                            <td></td>
                                                            <td>/</td>
                                                        </tr>
                                                        <tr>
                                                            <td>HDS2102S</td>
                                                            <td>100MHz</td>
                                                            <td>500MSa/s</td>
                                                            <td></td>
                                                            <td>/</td>
                                                        </tr>
                                                        <tr>
                                                            <td>HDS2202</td>
                                                            <td>200MHz</td>
                                                            <td>1GSa/s</td>
                                                            <td></td>
                                                            <td>/</td>
                                                        </tr>
                                                        <tr>
                                                            <td>HDS2202S</td>
                                                            <td>200MHz</td>
                                                            <td>1GSa/s</td>
                                                            <td>/</td>
                                                        </tr>

                                                        <td></td>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                    </div>

                                    <div className='px-4'>
                                        <div className='auto-img d-flex justify-content-center align-items-center'>
                                        <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>

                                        </div>
                                    </div>

                                </div>

                                }


                                {activeTab === 'tab2' && <div className='border p-3 bg-light'>

                                    <div>
                                        <h2>Waveform Generators</h2>
                                        <p className='fs-5'>
                                            We are one of the leading and professional waveform generators manufacturers and suppliers .
                                            Thanks to our highly qualified experts and advanced technology, we are also one of the world's
                                            leading brands. Welcome to buy the quality, cheap and durable waveform generator, function generator,
                                            arbitrary generator in stock from us. Check the quotation with us now.
                                        </p>
                                        <hr />
                                    </div>

                                    <div className='row'>

                                        <div className='col-md-3'>

                                            <img src='../Assets/wave-g-1.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON DGE2000 series Arbitrary Waveform Generator</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Frequency Output</th>
                                                            <th>Sample Rate</th>
                                                            <th>Arb Wave Length</th>
                                                            <th>Vertial rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>DGE2035</td>
                                                            <td>35MHz</td>
                                                            <td>125MSa/s</td>
                                                            <td>8k</td>
                                                            <td>14bits</td>
                                                        </tr>
                                                        <tr>
                                                            <td>DGE2070</td>
                                                            <td>70MHz</td>
                                                            <td>300MS/s</td>
                                                            <td>8k</td>
                                                            <td>14bits</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/wave-g-2.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON DGE3000 series Arbitrary Waveform Generator</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Frequency Output</th>
                                                            <th>Sample Rate</th>
                                                            <th>Arb Wave Length</th>
                                                            <th>Vertial rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>DGE3032</td>
                                                            <td>30MHz</td>
                                                            <td>125MSa/s</td>
                                                            <td>100k</td>
                                                            <td>14bits</td>
                                                        </tr>
                                                        <tr>
                                                            <td>DGE3062</td>
                                                            <td>60MHz</td>
                                                            <td>300MS/s</td>
                                                            <td>100k</td>
                                                            <td>14bits</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/wave-g-3.jpg' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON XDG3000 Series 2-CH 250MHz Arbitrary Waveform Generator</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Frequency Output</th>
                                                            <th>Sample Rate</th>
                                                            <th>Arb Wave Length</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>XDG2030</td>
                                                            <td>30MHz</td>
                                                            <td>500MS/s</td>
                                                            <td>10M</td>

                                                        </tr>
                                                        <tr>
                                                            <td>XDG2035</td>

                                                            <td>35MHz</td>
                                                            <td>500MS/s</td>
                                                            <td>10M</td>

                                                        </tr>
                                                        <tr>
                                                            <td>XDG2060	</td>

                                                            <td>60MHz</td>
                                                            <td>500MS/s</td>
                                                            <td>10M</td>

                                                        </tr>
                                                        <tr>
                                                            <td>XDG2080</td>

                                                            <td>80MHz</td>
                                                            <td>500MS/s</td>
                                                            <td>10M</td>

                                                        </tr>
                                                        <tr>
                                                            <td>XDG2100</td>

                                                            <td>100MHz</td>
                                                            <td>500MS/s</td>
                                                            <td>10M</td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/wave-g-5.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON XDG3000 Series 2-CH 250MHz Arbitrary Waveform Generator</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Frequency Output</th>
                                                            <th>Sample Rate</th>
                                                            <th>Arb Wave Length</th>
                                                            <th>Extra feature</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>XDG3252</td>
                                                            <td>250MHz</td>
                                                            <td>1.25GSa/s</td>
                                                            <td>1M pts</td>
                                                            <td>frequency counter; touch screen LCD</td>

                                                        </tr>
                                                        <tr>
                                                            <td>XDG3202	</td>

                                                            <td>200MHz</td>
                                                            <td>1.25GSa/s</td>
                                                            <td>1M pts</td>
                                                            <td>frequency counter; touch screen LCD</td>

                                                        </tr>
                                                        <tr>
                                                            <td>XDG3162</td>

                                                            <td>160MHz</td>
                                                            <td>1.25GSa/s</td>
                                                            <td>1M pts</td>
                                                            <td>frequency counter; touch screen LCD</td>

                                                        </tr>
                                                        <tr>
                                                            <td>XDG3102	</td>

                                                            <td>100MHz</td>
                                                            <td>1.25GSa/s</td>
                                                            <td>1M pts</td>
                                                            <td>frequency counter; touch screen LCD</td>

                                                        </tr>
                                                        <tr>
                                                            <td>XDG3082</td>

                                                            <td>80MHz</td>
                                                            <td>1.25GSa/s</td>
                                                            <td>1M pts</td>
                                                            <td>frequency counter; touch screen LCD</td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/wave-g-4.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON 2-CH Arbitrary Waveform Generator Without Counter</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Bandwidth</th>
                                                            <th>Sample Rate</th>
                                                            <th>Arb Wave Length</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>AG1012</td>
                                                            <td>10MHz</td>
                                                            <td>125MS/s</td>
                                                            <td>8K pts</td>

                                                        </tr>
                                                        <tr>
                                                            <td>AG1022	</td>

                                                            <td>25MHz	</td>
                                                            <td>125MS/s</td>
                                                            <td>8K pts</td>


                                                        </tr>




                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/wave-g-4.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON 2-CH Arbitrary Waveform Generator with Counter</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Bandwidth</th>
                                                            <th>Sample Rate</th>
                                                            <th>Arb Wave Length</th>
                                                            <th>Extra feature</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>AG1012F</td>
                                                            <td>10MHz</td>
                                                            <td>125MS/s</td>
                                                            <td>8K pts</td>
                                                            <td>frequency counter [range : 100mHz - 200MHz]</td>

                                                        </tr>
                                                        <tr>
                                                            <td>AG1022F	</td>
                                                            <td>25MHz	</td>
                                                            <td>125MS/s</td>
                                                            <td>8K pts</td>
                                                            <td>frequency counter [range : 100mHz - 200MHz]</td>


                                                        </tr>
                                                        <tr>
                                                            <td>AG2052F	</td>
                                                            <td>50MHz	</td>
                                                            <td>300MS/s</td>
                                                            <td>1M pts</td>
                                                            <td>frequency counter [range : 100mHz - 200MHz]</td>


                                                        </tr>
                                                        <tr>
                                                            <td>AG2062F	</td>
                                                            <td>60MHz	</td>
                                                            <td>300MS/s</td>
                                                            <td>1M pts</td>
                                                            <td>frequency counter [range : 100mHz - 200MHz]</td>


                                                        </tr>




                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/wave-g-6.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON 1-CH Low Frequency Arbitrary Waveform Generator</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Bandwidth</th>
                                                            <th>Sample Rate</th>
                                                            <th>Arb Wave Length</th>



                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>AG051</td>
                                                            <td>5MHz</td>
                                                            <td>125MS/s</td>
                                                            <td>2 pts to 8K pts</td>

                                                        </tr>
                                                        <tr>
                                                            <td>AG051F	</td>
                                                            <td>5MHz	</td>
                                                            <td>125MS/s</td>
                                                            <td>2 pts to 8K pts</td>



                                                        </tr>
                                                        <tr>
                                                            <td>AG1011	</td>
                                                            <td>10MHz	</td>
                                                            <td>125MS/s</td>
                                                            <td>2 pts to 8K pts</td>



                                                        </tr>
                                                        <tr>
                                                            <td>AG1011F		</td>
                                                            <td>10MHz	</td>
                                                            <td>125MS/s</td>
                                                            <td>2 pts to 8K pts</td>



                                                        </tr>




                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />
                                    </div>



                                    <div className='px-4'>
                                        <div className='auto-img d-flex justify-content-center align-items-center'>
                                        <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>



                                        </div>
                                    </div>

                                </div>}
                                {activeTab === 'tab3' && <div className='border p-3'>

                                    <div>
                                        <h2>Digital Multimeters</h2>
                                        <p className='fs-5'>
                                            We are one of the leading and professional digital multimeters manufacturers and suppliers .
                                            Thanks to our highly qualified experts and advanced technology, we are also one of the world's
                                            leading brands. Welcome to buy the quality, cheap and durable clamp meter in stock from us.
                                            Check the quotation with us now.
                                        </p>
                                        <hr />
                                    </div>


                                    <div className='row'>

                                        <div className='col-md-3'>

                                            <img src='../Assets/digit-1.jpg' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON 4 1/2 digits XDM1000 Series Bench-type Digital Multimeter</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Digits of Resolution</th>
                                                            <th>Reading Rate</th>
                                                            <th>Dispaly</th>
                                                            <th>Power supply</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>XDM1041</td>
                                                            <td>4 1/2</td>
                                                            <td>60 rdgs/s</td>
                                                            <td>3.5" LCD</td>
                                                            <td>AC Power</td>
                                                        </tr>
                                                        <tr>
                                                            <td>DGE2070</td>
                                                            <td>4 1/2</td>
                                                            <td>60 rdgs/s</td>
                                                            <td>3.5" LCD</td>
                                                            <td>Lithium Battery</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/digit-2.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON 4 1/2 digits XDM1141 Bench-type Digital Multimeter</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Digits of Resolution</th>
                                                            <th>Reading Rate</th>
                                                            <th>Dispaly</th>
                                                            <th>IO Interface</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>XDM1141</td>
                                                            <td>4 1/2</td>
                                                            <td>65 rdgs/s</td>
                                                            <td>3.5" LCD</td>
                                                            <td>USB Device</td>
                                                        </tr>



                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/digit-3.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON Digital Clamp Meter CM</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>AC Voltage</th>
                                                            <th>DC Voltage</th>
                                                            <th>AC Current</th>
                                                            <th>Display Count</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>CM240</td>
                                                            <td>0 - 600V</td>
                                                            <td>0 - 600V</td>
                                                            <td>0 - 400A</td>
                                                            <td>2000</td>

                                                        </tr>





                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/digit-4.jpg' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>CM2100 Series Smart AC/DC Clamp Meter</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>AC/DC Voltage</th>
                                                            <th>AC/DC Current</th>
                                                            <th>Display Count</th>
                                                            <th>Bluetooth</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>CM2100B</td>
                                                            <td>600V</td>
                                                            <td>100A</td>
                                                            <td>20000</td>
                                                            <td></td>

                                                        </tr>
                                                        <tr>
                                                            <td>XDG3202	</td>

                                                            <td>600V</td>
                                                            <td>100A</td>
                                                            <td>20000</td>
                                                            <td></td>

                                                        </tr>




                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/digit-5.jpg' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>4 1/2 digits Bench-type XDM2041 Digital Multimeter</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Digits of Resolution</th>
                                                            <th>Reading Rate</th>
                                                            <th>Dispaly</th>
                                                            <th>IO Interface</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>XDM2041</td>
                                                            <td>4 1/2</td>
                                                            <td>60 rdgs/s</td>
                                                            <td>4" LCD</td>
                                                            <td>RS232</td>

                                                        </tr>





                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/digit-6.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON 5 1/2 Bench-type XDM3051 Digital Multimeter</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Digits of Resolution</th>
                                                            <th>Reading Rate</th>
                                                            <th>Dispaly</th>
                                                            <th>IO Interface</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>XDM3051</td>
                                                            <td>5 1/2</td>
                                                            <td>150 rdgs/s</td>
                                                            <td>4" LCD</td>
                                                            <td>USB / RS232 / LAN / WiFi</td>

                                                        </tr>







                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/wave-g-6.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON 4 1/2 Bench-type XDM3041 Digital Multimeter</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Digits of Resolution</th>
                                                            <th>Reading Rate</th>
                                                            <th>Dispaly</th>
                                                            <th>IO Interface</th>



                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>XDM3041	</td>
                                                            <td>4 1/2</td>
                                                            <td>150 rdgs/s</td>
                                                            <td>4" LCD</td>
                                                            <td>USB / RS232 / LAN / WiFi</td>

                                                        </tr>







                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />
                                    </div>


                                    <div className='px-4'>
                                        <div className='auto-img d-flex justify-content-center align-items-center'>
                                        <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>


                                        </div>
                                    </div>


                                </div>}

                                {activeTab === 'tab4' && <div className='border p-3'>

                                    <div>
                                        <h2>Power Supplies</h2>
                                        <p className='fs-5'>
                                            We are one of the leading and professional power supplies manufacturers and suppliers .
                                            Thanks to our highly qualified experts and advanced technology, we are also one of the world's
                                            leading brands. Welcome to buy the quality, cheap and durable desktop power supply, power supply
                                            digital in stock from us. Check the quotation with us now.
                                        </p>
                                        <hr />
                                    </div>
                                    <div className='row'>

                                        <div className='col-md-3'>

                                            <img src='../Assets/power-1.jpg' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWP-H Series High Power DC Power Supply</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Output Power</th>
                                                            <th>Output Voltage</th>
                                                            <th>Output Current</th>
                                                            <th>Channel</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>OWP1000-H series</td>
                                                            <td>1KW</td>
                                                            <td>60V-300V</td>
                                                            <td>5A-30A</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>OWP2000-H series</td>
                                                            <td>2KW</td>
                                                            <td>45V-600V</td>
                                                            <td>8A-100A</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>OWP3000-H series</td>
                                                            <td>3KW</td>
                                                            <td>45V-600V</td>
                                                            <td>8A-100A</td>
                                                            <td>1</td>
                                                        </tr>

                                                        <tr>
                                                            <td>OWP6000-H series</td>
                                                            <td>6KW</td>
                                                            <td>100V-1500V</td>
                                                            <td>4A-100A</td>
                                                            <td>1</td>
                                                        </tr>

                                                        <tr>
                                                            <td>OWP8000-H series	</td>
                                                            <td>8KW</td>
                                                            <td>100V-1000V	</td>
                                                            <td>10A-100A</td>
                                                            <td>1</td>
                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/power-2.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON SPE Series 1 CH DC Power Supply</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Output Power</th>
                                                            <th>Output Ranges</th>
                                                            <th>Channel</th>
                                                            <th>Output Resolution</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>SPE3051</td>
                                                            <td>150W</td>
                                                            <td>0-30V / 0-5A</td>
                                                            <td>1</td>
                                                            <td>10mV/1mA</td>
                                                        </tr>

                                                        <tr>
                                                            <td>SPE3102</td>
                                                            <td>200W</td>
                                                            <td>0-30V / 0-10A</td>
                                                            <td>1</td>
                                                            <td>10mV/1mA</td>
                                                        </tr>

                                                        <tr>
                                                            <td>SPE6102</td>
                                                            <td>200W</td>
                                                            <td>0-60V / 0-10A</td>
                                                            <td>1</td>
                                                            <td>10mV/1mA</td>
                                                        </tr>

                                                        <tr>
                                                            <td>SPE6053</td>
                                                            <td>300W</td>
                                                            <td>0-60V / 0-5A</td>
                                                            <td>1</td>
                                                            <td>10mV/1mA</td>
                                                        </tr>


                                                        <tr>
                                                            <td>SPE3103</td>
                                                            <td>300W</td>
                                                            <td>0-30V / 0-10A</td>
                                                            <td>1</td>
                                                            <td>10mV/1mA</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SPE6103</td>
                                                            <td>300W</td>
                                                            <td>0-60V / 0-10A</td>
                                                            <td>1</td>
                                                            <td>10mV/1mA</td>
                                                        </tr>







                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/power-3.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON SPM Series 1 CH DC Power Multimeter</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Output Range</th>
                                                            <th>Out put Power</th>
                                                            <th>Multimeter</th>
                                                            <th>Power resolution</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>SPM3051</td>
                                                            <td>30V/5A</td>
                                                            <td>150W</td>
                                                            <td>4 1/2 digits</td>
                                                            <td>10mV/1mA</td>

                                                        </tr>
                                                        <tr>
                                                            <td>SPM6053</td>
                                                            <td>60V/5A</td>
                                                            <td>300W</td>
                                                            <td>4 1/2 digits</td>
                                                            <td>10mV/1mA</td>

                                                        </tr>
                                                        <tr>
                                                            <td>SPM3103</td>
                                                            <td>30V/10A</td>
                                                            <td>300W</td>
                                                            <td>4 1/2 digits</td>
                                                            <td>10mV/1mA</td>

                                                        </tr>
                                                        <tr>
                                                            <td>SPM6103</td>
                                                            <td>60V/10A</td>
                                                            <td>300W</td>
                                                            <td>4 1/2 digits</td>
                                                            <td>10mV/1mA</td>

                                                        </tr>






                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/power-4.jpg' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON SP Series 1 CH DC Power Supply</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Output Power</th>
                                                            <th>Output Ranges</th>
                                                            <th>Channel</th>
                                                            <th>Output Resolution</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>SP3051</td>
                                                            <td>150W</td>
                                                            <td>0-30V / 0-5A</td>
                                                            <td>1</td>
                                                            <td>10mV/1mA</td>

                                                        </tr>
                                                        <tr>
                                                            <td>SP6101	</td>

                                                            <td>150W</td>
                                                            <td>0-30V / 0-10A</td>
                                                            <td>1</td>
                                                            <td>10mV/1mA</td>

                                                        </tr>

                                                        <tr>
                                                            <td>SP3101	</td>

                                                            <td>150W</td>
                                                            <td>0-60V / 0-10A</td>
                                                            <td>1</td>
                                                            <td>10mV/1mA</td>

                                                        </tr>

                                                        <tr>
                                                            <td>SP6053	</td>

                                                            <td>300W</td>
                                                            <td>0-60V / 0-5A</td>
                                                            <td>1</td>
                                                            <td>10mV/1mA</td>

                                                        </tr>

                                                        <tr>
                                                            <td>SP3103	</td>

                                                            <td>300W</td>
                                                            <td>0-30V / 0-10A</td>
                                                            <td>1</td>
                                                            <td>10mV/1mA</td>

                                                        </tr>
                                                        <tr>
                                                            <td>SP6103</td>

                                                            <td>300W</td>
                                                            <td>0-60V / 0-10A</td>
                                                            <td>1</td>
                                                            <td>10mV/1mA</td>

                                                        </tr>




                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/power-5.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON P4000 Series 1CH Liner DC Power Supply</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Output Power</th>
                                                            <th>Output Ranges</th>
                                                            <th>Channel</th>
                                                            <th>Output Resolution</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>P4305</td>
                                                            <td>150W</td>
                                                            <td>0 - 30V / 0 - 5A</td>
                                                            <td>1</td>
                                                            <td>1mV / 1mA</td>

                                                        </tr>
                                                        <tr>
                                                            <td>P4603</td>
                                                            <td>180W</td>
                                                            <td>0 - 60V / 0 - 3A</td>
                                                            <td>1</td>
                                                            <td>1mV / 1mA</td>

                                                        </tr>





                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/power-6.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>
                                                OWON 12A / 6A Dual Output Programmable DC Power Supply</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Output Power</th>
                                                            <th>Output Ranges</th>
                                                            <th>Channel</th>
                                                            <th>Output Resolution</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>ODP3122</td>
                                                            <td>378W</td>
                                                            <td>0 - 30V / 12A-CH | 0 - 6V / 3A</td>
                                                            <td>2+Sense</td>
                                                            <td>1mV / 1mA</td>

                                                        </tr>
                                                        <tr>
                                                            <td>ODP6062</td>
                                                            <td>378W</td>
                                                            <td>0 - 60V / 6A-CH | 0 - 6V / 3A</td>
                                                            <td>2+Sense</td>
                                                            <td>1mV / 1mA</td>

                                                        </tr>







                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/power-7.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>
                                                OWON 3-CH Output with 2-CH 0-30V/6A and 0-6V/3A Programmable DC Power Supply</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Output Power</th>
                                                            <th>Output Ranges</th>
                                                            <th>Channel</th>
                                                            <th>Output Resolution</th>



                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>ODP3033	</td>
                                                            <td>198W</td>
                                                            <td>0 - 30V / 3A x 2-CH | 0 - 6V / 3A</td>
                                                            <td>3</td>
                                                            <td>1mV / 1mA</td>

                                                        </tr>

                                                        <tr>
                                                            <td>ODP3063		</td>
                                                            <td>378W</td>
                                                            <td>0 - 30V / 6A x 2-CH | 0 - 6V / 3A</td>
                                                            <td>3</td>
                                                            <td>1mV / 1mA</td>

                                                        </tr>

                                                        <tr>
                                                            <td>ODP6033	</td>
                                                            <td>378W</td>
                                                            <td>0 - 60V / 3A x 2-CH | 0 - 6V / 3A</td>
                                                            <td>3</td>
                                                            <td>1mV / 1mA</td>

                                                        </tr>
                                                        <tr>
                                                            <td>ODP3033	</td>
                                                            <td>198W</td>
                                                            <td>0 - 30V / 3A x 2-CH | 0 - 6V / 3A</td>
                                                            <td>3</td>
                                                            <td>1mV / 1mA</td>

                                                        </tr>







                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/power-8.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>
                                                OWON 2-CH Output With 5V Fixed Programmable DC Power Supply
                                            </h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Output Power</th>
                                                            <th>Output Ranges</th>
                                                            <th>Output Current</th>
                                                            <th>Channel</th>



                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>ODP3032	</td>
                                                            <td>195W</td>
                                                            <td>0 - 30V | fixed 5V</td>
                                                            <td>0 - 3A | fixed 3A</td>
                                                            <td>2+1</td>

                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />
                                    </div>


                                    <div className='px-4'>
                                        <div className='auto-img d-flex justify-content-center align-items-center'>
                                        <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>


                                        </div>
                                    </div>


                                </div>}

                                {activeTab === 'tab5' && <div className='border p-3'>

                                    <div>
                                        <h2>Spectrum Analyzers</h2>
                                        <p className='fs-5'>
                                            Spectrum Analyzer
                                        </p>
                                        <hr />
                                    </div>
                                    <div className='row'>

                                        <div className='col-md-3'>

                                            <img src='../Assets/spect-1.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>
                                                XSA1000P-TG Series Spectrum Analyzer</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Frequency Range</th>
                                                            <th>RBW</th>
                                                            <th>Phase Noise (Typical)</th>
                                                            <th>TG Model</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>XSA1015P</td>
                                                            <td>9kHz-1.5GHz</td>
                                                            <td>1Hz - 1MHz</td>
                                                            <td>-106 dBc/Hz @1GHz</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>XSA1036P</td>
                                                            <td>9kHz-3.6GHz</td>
                                                            <td>1Hz - 1MHz</td>
                                                            <td>-106 dBc/Hz @1GHz</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>XSA1075P</td>
                                                            <td>9kHz-7.5 GHz</td>
                                                            <td>1Hz - 1MHz</td>
                                                            <td>-106 dBc/Hz @1GHz	</td>
                                                            <td></td>
                                                        </tr>

                                                        <tr>
                                                            <td>XSA1015PTG</td>
                                                            <td>9kHz-1.5GHz</td>
                                                            <td>1Hz - 1MHz	</td>
                                                            <td>-106 dBc/Hz @1GHz</td>
                                                            <td></td>
                                                        </tr>

                                                        <tr>
                                                            <td>XSA1036PTG	</td>
                                                            <td>9kHz-3.6GHz</td>
                                                            <td>1Hz - 1MHz		</td>
                                                            <td>-106 dBc/Hz @1GHz</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>XSA1075PTG	</td>
                                                            <td>9kHz-7.5 GHz</td>
                                                            <td>1Hz - 1MHz		</td>
                                                            <td>-106 dBc/Hz @1GHz</td>
                                                            <td>1</td>
                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/spect-2.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>
                                                XSA800 Series Spectrum Analyzer</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Frequency Range</th>
                                                            <th>RBW</th>
                                                            <th>Phase Noise (Typical)</th>
                                                            <th>TG Model</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>XSA805</td>
                                                            <td>9kHz - 500MHz</td>
                                                            <td>1Hz - 1MHz</td>
                                                            <td>-80 dBc/Hz @1GHz and offset at 10 kHz</td>
                                                            <td></td>
                                                        </tr>

                                                        <tr>
                                                            <td>XSA805TG</td>
                                                            <td>9kHz - 500MHz</td>
                                                            <td>1Hz - 1MHz</td>
                                                            <td>80 dBc/Hz @1GHz and offset at 10 kHz</td>
                                                            <td></td>
                                                        </tr>

                                                        <tr>
                                                            <td>XSA810</td>
                                                            <td>9kHz - 1GHz</td>
                                                            <td>1Hz - 1MHz	</td>
                                                            <td>-80 dBc/Hz @1GHz and offset at 10 kHz</td>
                                                            <td></td>
                                                        </tr>

                                                        <tr>
                                                            <td>XSA810TG	</td>
                                                            <td>9kHz - 1GHz</td>
                                                            <td>1Hz - 1MHz</td>
                                                            <td>-80 dBc/Hz @1GHz and offset at 10 kHz</td>
                                                            <td></td>
                                                        </tr>


                                                        <tr>
                                                            <td>XSA815</td>
                                                            <td>9kHz - 1.5GHz</td>
                                                            <td>1Hz - 1MHz</td>
                                                            <td>-80 dBc/Hz @1GHz and offset at 10 kHz</td>
                                                            <td>10mV/1mA</td>
                                                        </tr>
                                                        <tr>
                                                            <td>XSA815TG</td>
                                                            <td>9kHz - 1.5GHz</td>
                                                            <td>1Hz - 1MHz</td>
                                                            <td>-80 dBc/Hz @1GHz and offset at 10 kHz</td>
                                                            <td></td>
                                                        </tr>







                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/spect-3.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON HSA1016/1036/1075 Handheld Spectrum Analyzer</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Frequency Range</th>
                                                            <th>RBW</th>
                                                            <th>	TG Model</th>
                                                            <th>GPS</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>HSA1016</td>
                                                            <td>9kHz - 1.6GHz</td>
                                                            <td>10Hz - 3MHz</td>
                                                            <td>x</td>
                                                            <td>10mV/1mA</td>

                                                        </tr>
                                                        <tr>
                                                            <td>HSA1016TG</td>
                                                            <td>9kHz - 1.6GHz</td>
                                                            <td>10Hz - 3MHz</td>
                                                            <td></td>
                                                            <td></td>

                                                        </tr>
                                                        <tr>
                                                            <td>HSA1036</td>
                                                            <td>9kHz - 3.6GHz</td>
                                                            <td>10Hz - 3MHz</td>
                                                            <td>x</td>
                                                            <td>x</td>

                                                        </tr>
                                                        <tr>
                                                            <td>HSA1036TG</td>
                                                            <td>9kHz - 3.6GHz</td>
                                                            <td>10Hz - 3MHz</td>
                                                            <td></td>
                                                            <td></td>

                                                        </tr>
                                                        <tr>
                                                            <td>HSA1075</td>
                                                            <td>9kHz - 7.5GHz</td>
                                                            <td>10Hz - 3MHz</td>
                                                            <td>x</td>
                                                            <td></td>

                                                        </tr>
                                                        <tr>
                                                            <td>HSA1075TG</td>
                                                            <td>9kHz - 7.5GHz</td>
                                                            <td>10Hz - 3MHz</td>
                                                            <td></td>
                                                            <td></td>

                                                        </tr>






                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/spect-4.png' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>
                                                XSA1000TG Series Spectrum Analyzer</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Frequency Range</th>
                                                            <th>RBW</th>
                                                            <th>Phase Noise (Typical)</th>
                                                            <th>TG Model</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>XSA1015-TG</td>
                                                            <td>9kHz - 1.5GHz</td>
                                                            <td>10Hz - 3MHz</td>
                                                            <td>-82dBc/Hz@10KH</td>
                                                            <td></td>

                                                        </tr>
                                                        <tr>
                                                            <td>XSA1032-TG	</td>

                                                            <td>9kHz - 3.2GHz</td>
                                                            <td>10Hz - 3MHz</td>
                                                            <td>82dBc/Hz@10KHz</td>
                                                            <td></td>

                                                        </tr>

                                                        <tr>
                                                            <td>XSA1036-TG	</td>

                                                            <td>9kHz - 3.6GHz</td>

                                                            <td>10Hz - 3MHz</td>
                                                            <td>98dBc/Hz@10kHz</td>
                                                            <td></td>


                                                        </tr>

                                                        <tr>
                                                            <td>XSA1075-TG	</td>

                                                            <td>9kHz - 7.5GHz</td>
                                                            <td>10Hz - 3MHz</td>
                                                            <td>-98dBc/Hz@10kHz</td>
                                                            <td></td>


                                                        </tr>



                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />

                                        <div className='col-md-3'>

                                            <img src='../Assets/spect-5.jpg' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>OWON HSA1015 / 1032 Handheld Spectrum Analyzer</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Frequency Range</th>
                                                            <th>RBW</th>
                                                            <th>TG Model</th>
                                                            <th>GPS</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>HSA015</td>
                                                            <td>9kHz - 1.5GHz</td>
                                                            <td>10Hz - 3MHz</td>
                                                            <td>x</td>
                                                            <td>x</td>

                                                        </tr>
                                                        <tr>
                                                            <td>HSA015TG</td>
                                                            <td>9kHz - 1.5GHz</td>
                                                            <td>10Hz - 3MHz</td>
                                                            <td></td>
                                                            <td>x</td>

                                                        </tr>
                                                        <tr>
                                                            <td>HSA032</td>
                                                            <td>9kHz - 3.2GHz</td>
                                                            <td>10Hz - 3MHz</td>
                                                            <td>x</td>
                                                            <td>x</td>

                                                        </tr>
                                                        <tr>
                                                            <td>HSA032TG</td>
                                                            <td>9kHz - 3.2GHz</td>
                                                            <td>10Hz - 3MHz</td>
                                                            <td></td>
                                                            <td>x</td>

                                                        </tr>





                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />
                                    </div>


                                    <div className='px-4'>
                                        <div className='auto-img d-flex justify-content-center align-items-center'>
                                        <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>


                                        </div>
                                    </div>


                                </div>}

                                {activeTab === 'tab6' && <div className='border p-3'>
                                    <div>
                                        <h2>Thermal Imagers
                                        </h2>
                                        <p className='fs-5'>
                                            Thermal Imagers

                                        </p>
                                        <hr />
                                    </div>

                                    <div className='row'>
                                        <div className='col-md-3'>

                                            <img src='../Assets/thermal.jpg' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>
                                                Handheld Thermal Imaging Camera</h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Infrared resolution</th>
                                                            <th>visible resolution</th>
                                                            <th>Temperature measurement</th>
                                                            <th>Display</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>TI332</td>
                                                            <td>320 x 240 px</td>
                                                            <td>640 x 480 px	</td>
                                                            <td>-20 °C to 450 °C</td>
                                                            <td>3.5" TFT LCD</td>

                                                        </tr>
                                                        
                                                        <tr>
                                                            <td>TI322</td>
                                                            <td>220 x 160 px</td>
                                                            <td>640 x 480 px</td>
                                                            <td>-20 °C to 450 °C</td>
                                                            <td>3.5" TFT LCD</td>

                                                        </tr>
                                                       





                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />
                                        <div className='px-4'>
                                        <div className='auto-img d-flex justify-content-center align-items-center'>
                                        <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>


                                        </div>
                                    </div>

                                    </div>

                                </div>}

                                {activeTab === 'tab7' && <div className='border p-3'>
                                    <div>
                                        <h2>Anenmo Meter
                                        </h2>
                                        <p className='fs-5'>
                                        Anenmo Meter

                                        </p>
                                        <hr />
                                    </div>

                                    <div className='row'>
                                        <div className='col-md-3'>

                                            <img src='../Assets/ameno.jpg' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-9 mt-4'>
                                            <h4>
                                            OWM5500 Smart Anemo Meter
                                            </h4>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Model No</th>
                                                            <th>Wind speed</th>
                                                            <th>Humidity</th>
                                                            <th>Temperature</th>
                                                            <th>Air volume</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>OWM5500</td>
                                                            <td>0.6 - 40 m/s</td>
                                                            <td>5 - 95 %RH	</td>
                                                            <td>-10 - 50 ℃</td>
                                                            <td>3.5" TFT LCD</td>

                                                        </tr>
                                                        
                                                        <tr>
                                                            <td>TI322</td>
                                                            <td>220 x 160 px</td>
                                                            <td>640 x 480 px</td>
                                                            <td>-20 °C to 450 °C</td>
                                                            <td>3.5" TFT LCD</td>

                                                        </tr>
                                                       





                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <hr />
                                        <div className='px-4'>
                                        <div className='auto-img d-flex justify-content-center align-items-center'>
                                        <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>


                                        </div>
                                    </div>

                                    </div>

                                </div>}
                            </div>
                        </div>
                    </div>
                </div>






            </div>
            <Whatapp />
            <Footer />

        </>
    )
}

export default Generalpurpose