import React from 'react'
import Header from '../cmp/Header'
import Footer from './Footer'
import Whatapp from '../cmp/whatapp'
import { Link } from 'react-router-dom'
const Aboutus = () => {
  return (
    <>
      <div>
        <Header />
        <div className='container-fluid p-0'>
          <div>
            <img src='../Assets/aboutus.jpg' width='100%' style={{height:'300px'}} />
          </div>
          <div className='container py-3'>
            
            <p className='fs-5' style={{ textAlign: 'justify' }}>
              Ashtronix Solutions is a Bangalore (India) based company, we are pioneer in distribution and services of Testing & measuring instruments. Our primary intent is to provide high quality & innovative products/technologies to our customers and support them with quality after sale services. We also educate our customers time to time about the new technologies/products to get full benefits of this new era equipment’s. Our fundamental strength is our commitment to provide right product and quality support at the right time to our valued customer and to be a part of their success. We have larger customer base in the areas of Electrical, Electronics, Telecommunication, Power and Government (Space & Defence) Education sectors (Research Institutes ) .

            </p>
            <p className='fs-5' style={{ textAlign: 'justify' }}>

              The company has a team of well qualified and certified engineers who can understand the customers need and provide solutions that are the right fit for the application. The company can also provide trainings in Measurement Technologies across various domains
              Transparency, Integrity and honoring the commitments has helped Ashtronix Solutions to be considered as a specialized preferred vendor for providing leading edge Electronic Test and Measuring Instruments

            </p>
          </div>

          <div className='container-fluid py-4' >
            <div className='container'>
              <h3 className='text-center fw-bold fs-1 mb-3' style={{ color: '#0A0080' }}>OUR VISION</h3>
              <p className='fs-5 ' style={{ textAlign: 'justify' }}>
              To be a specialist in the field of Electronics instrumentation with a profitable growth and leveraging the strengths to diversify and provide quality products and services to customers.
              </p>
            </div>
          </div>

          <div className='container py-4'>
            <h3 className='text-center fw-bold fs-1 mb-3' style={{ color: '#0A0080' }}>WE ACHIEVE THIS THROUGH</h3>
            <p className='fs-5 ' style={{ textAlign: 'justify' }}>
            We have maintained valuable associations with clients from all major sectors like Industrial, Defense, Education and Government. With our customer driven ideology and sincere efforts, We continue to deliver quality products at competitive prices.
            </p>

            <p className='fs-5 ' style={{ textAlign: 'justify' }}>
            We have diligent and result oriented teams of committed individuals for each department who help realize our vision to reality. Our Technical team is well trained and skilled to provide full assistance to our customers.
            </p>

            <p className='fs-5 ' style={{ textAlign: 'justify' }}>
            We strive for Sales and After – Sales Service to keep our customers satisﬁed with continuous improvement in our Performance to ensure that integrity of our business is never lost.
            </p>
          </div>

          {/* <div className='container-fluid py-4' style={{ backgroundColor: '#E6E6E6' }}>
            <div className='container'>
              <h3 className='text-center fw-bold fs-1 mb-4' style={{ color: '#0A0080' }}>Message from MD’s desk</h3>
              <div className='row'>
                <div className='col-md-6'>
                  <p className='fs-5' style={{ textAlign: 'justify' }}>
                    Greetings to all.<br />

                    I have great pleasure in announcing the completion of 10 years of a successful journey for Ashtronix Solutions . This has been an eventful journey. We have been a great and enduring organization mainly because of our steadfast commitment to our values of trust, integrity, transparency, ethical practices, and commitment to customer care

                    We rededicate ourselves to vigorous growth with dignity and pride in the years to come.

                    On behalf of our company, I would like to convey our deepest gratitude to all our customers, suppliers, and all those who have posed their confidence in us.
                    <p className='mt-3'>Nishar Ahmed</p>

                    <h5>Managing Director</h5>
                  </p>
                </div>
                <div className='col-md-6'>
                  <img src='../Assets/waveform.png' width='100%' />

                </div>
              </div>
            </div>
          </div> */}


        </div>

        <div className='container py-5 text-center'>
          <img src='../Assets/Contact-us.jpg' width='90%' />
          <div style={{ position: 'relative', top: '-140px' }}>
            <button className='btn p-2 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Contact Us</Link></button>

          </div>
        </div>
      </div>
      <Whatapp />
      <Footer />
    </>

  )
}

export default Aboutus