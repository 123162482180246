import { useState } from 'react'
import Footer from '../cmp/Footer'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from './Header'
import Whatapp from './whatapp'
import  '../cmp/css/Header.css'
import { Carousel, Nav, Navbar, Container, Dropdown, Button, Form, NavDropdown } from 'react-bootstrap';
const Home = () => {
    
   
    return (
        <>
            <div>
                <Header />
                <div className='container-fluid px-0'>
                    <Carousel data-bs-theme="dark">
                        <Carousel.Item interval={2000} className='slider'>
                           <div className='d-flex'>
                            <img
                                className="d-block w-50"
                                src="../Assets/power-electronic.png"
                                alt="First slide"
                                id='mob-resp'
                            />
                             <div className='w-50 pt-5'>
                                <h3 className='mt-5 mb-5 res-title' style={{color:'blanchedalmond'}}>AC Testing Power Supply</h3>
                                <p className='fs-5 res-title' style={{color:'blanchedalmond'}}>applied for the labs and production lines in power electronics,<br /> avionics, IEC standard test and other industries.</p>
                                
                             </div>
                            </div>
                            <Carousel.Caption>
                              
                            </Carousel.Caption>
                        </Carousel.Item>
                         <Carousel.Item interval={2000} className='slider'>
                        <div className='d-flex'>
                            <img
                                className="d-block w-50 "
                                src="../Assets/solar_power.png"
                                alt="First slide"
                                id='mob-resp-1'
                            />
                             <div className='w-50 pt-5'>
                                <h3 className='mt-5 mb-5 res-title' style={{color:'blanchedalmond'}}>Solar Cell Simulator</h3>
                                <p className='fs-5 res-title' style={{color:'blanchedalmond'}}>
                                New energy photovoltaic inverter, energy storage converter. <br />It has high power factor, fast dynamic response.

                                </p>
                                
                             </div>
                            </div>
                            <Carousel.Caption>
                               
                            </Carousel.Caption>
                        </Carousel.Item> 
                        <Carousel.Item interval={2000} className='slider'>
                        <div className='d-flex'>
                            <img
                                className="d-block w-50 mob-resp"
                                src="../Assets/electrical_safety.png"
                                alt="First slide"
                                id='mob-resp-2'
                            />
                             <div className='w-50 pt-5'>
                                <h3 className='mt-5 mb-5 res-title' style={{color:'blanchedalmond'}}>Efficient Electrical Safety</h3>
                                <p className='fs-5 res-title'style={{color:'blanchedalmond'}}>
                                Household appliances, energy-saving lamps, medical equipment,<br /> information equipment, audio and video equipment, <br />laboratory equipment, and charging piles.

                                </p>
                                
                             </div>
                            </div>
                            <Carousel.Caption>
                               
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>

                </div>


                <div className='container-fluid px-0 py-5' style={{ backgroundColor: '#E6E6E6' }}>
                    <div className='container mb-5'>
                        
                        <h3 style={{ color: '#0A0080', fontSize: '35px' }} className='mb-3 text-center'>WHO ARE WE</h3>
                        <div className='row'>
                            <div className='col '>
                        <p className='fs-5 mb-4 fs-5-container' style={{textAlign:'justify'}}>
                        Ashtronix Solutions is a Bangalore (India) based company, we are pioneer in distribution and 
                        services of Testing & measuring instruments. Our primary intent is to provide high quality & innovative 
                        products/technologies to our customers and support them with quality after sale services. We also educate
                         our customers time to time about the new technologies/products to get full benefits of this new era equipment’s. Our fundamental strength is our commitment to provide right product and quality support at the right time to our valued customer and to be a part of their success. 
                        </p>
                        <div className='text-center'>
                        
                      </div>
                      </div>
                    </div>
                    </div>
                </div>

                {/* <div className='container'>
                    <div className='row test-res' style={{ height: '550px',width:'100%' }}>
                        <div className='col-md-6'>
                            <div style={{ position: 'relative' }}>
                                <div class="card card1 res-mob" style={{ width: '30rem', position: 'absolute', top: '-60px', left: '120px' }}>
                                    <img src="../Assets/spectrum_ana.png" class="card-img-top" alt="..." />
                                    <div class="card-body text-white" style={{ backgroundColor: '#545454' }}>
                                        <h5 class="card-title fs-3 text-start">Spectrum Analyzers</h5>
                                        <p></p>
                                        <p className='text-start mb-0 fs-5'>Bandwidth: 1.5GHz-26.5GHz</p>
                                        <p className='text-start mb-0 fs-5'>DANL: -163dBm</p>
                                        <p className='text-start mb-0 fs-5'>Tracking Generator: Optional</p>
                                        < p className='text-start mb-0 fs-5 mb-5'>RBW: 1Hz - 8MHz</p>
                                        <p></p>
                                    </div>


                                </div>
                            </div>
                        </div>


                        <div className='col-md-6'>
                            <div style={{ position: 'relative' }}>
                                <div class="card card1 res-mob" style={{ width: '30rem', position: 'absolute', top: '-60px', left: '-10px' }}>
                                    <img src="../Assets/range.png" class="card-img-top" alt="..." />
                                    <div class="card-body text-white" style={{ backgroundColor: '#545454' }}>
                                        <h6 class="card-title fs-3 text-start">Microohm Meter</h6>
                                        <p></p>
                                        <p className='text-start mb-0 fs-5'>Measurement Range: 20kΩ – 2MΩ</p>
                                        <p className='text-start mb-0 fs-5'>Maximum Test Rate : 60 times per second</p>
                                        <p className='text-start mb-0 fs-5'>Connectivity: USB and RS232</p>
                                        <p className='text-start mb-0 fs-5'>Wide measurement range, 200V to 1μV, 3A to 10pA, 200MΩ to 10μΩ</p>
            
                                        <p></p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div> */}

                <div>

                    <div className='container-fluid py-3' >
                        <h2 className='text-center fs-2' style={{ color: '#0A0080', fontWeight: 'bold', marginBottom: '40px' }}>Featured Products</h2>

                        <div className='container-fluid mb-5'>
                        <h1 className='text-center display-6 fw-semibold text-danger'>Coming Soon</h1>
                            <Carousel data-bs-theme="dark">

                              
                              {/* <Carousel.Item style={{ backgroundColor: 'inherit' }}>
                                     <div className='row'>
                                        <div className='col-md-6 text-center'>
                                            <img src='./Assets/product-1.png' className='prd-img' />
                                        </div>

                                        <div className='col-md-6 px-5'>
                                            <h3 style={{ color: '#0A0080' }} className='text-start '>5 Series MSO</h3>
                                            <p></p>
                                            <div className='text-dark text-start fs-5'>
                                                <p>More ways to see more signals <br />

                                                    Versatile inputs and advanced probing technology let you see more.
                                                </p>
                                                <p>
                                                    <ul>
                                                        <li>
                                                            Up to 8 FlexChannel™&nbsp;inputs for up to 8 analog or 64 digital signals, plus an external trigger input
                                                        </li>
                                                        <li>
                                                            Includes 500 MHz or 1 GHz probes with just 3.9 pF input capacitance.
                                                        </li>
                                                        <li>
                                                            TekVPI probe interface supports a wide range of active, differential, isolated and current probes
                                                        </li>
                                                        <li>
                                                            Capture data outside the lab and collaborate easily with engineers on the next bench or in the next time-zone.
                                                        </li>
                                                        <li>
                                                            See signal details with high-resolution 12-bit analog-to-digital&nbsp;converters.
                                                        </li>
                                                    </ul>

                                                    <button className='btn p-2 fs-5' style={{ backgroundColor: '#E22D2C', color: '#fff', }}>Download</button>
                                                </p>
                                            </div>

                                        </div>

                                    </div> 
                                    <Carousel.Caption>

                                    </Carousel.Caption>
                                </Carousel.Item>  */}
                                {/* <Carousel.Item style={{ backgroundColor: 'inherit' }}>
                                    <div className='row'>
                                        <div className='col-md-6 text-center'>
                                            <img src='./Assets/product-2.png' height='400px' className='prd-img' />
                                        </div>

                                        <div className='col-md-6 px-5'>
                                            <h3 style={{ color: '#0A0080' }} className='text-start '>EV Charger Monitoring&nbsp;System</h3>

                                            <p></p>
                                            <div className='text-dark text-start fs-5'>
                                                <p>24/7 power quality monitoring of EV chargers with cloud connectivity and real-time alerts.</p>
                                                <p>The PQube3 certified power quality analyzer allows us to measure, identify and record all power quality disturbances including IEC 61000-4-30 Ed3 Class A parameters, conducted emissions and high frequency transients. Perform reliable measurement of AC &amp; DC parameters in a synchronized manner. Study the impact of harmonic distortions on the grid. Additionally, PQube3 is a revenue grade ANSI Class 0.2 accuracy energy meter that allows you to track the EV charger’s energy consumption over time.</p>

                                                <button className='btn p-2 fs-5' style={{ backgroundColor: '#E22D2C', color: '#fff', }}>Contact Us</button>

                                            </div>

                                        </div>

                                    </div>
                                    <Carousel.Caption>

                                    </Carousel.Caption>
                                </Carousel.Item> */}
                                {/* <Carousel.Item style={{ backgroundColor: 'inherit' }}>
                                    <div className='row'>
                                        <div className='col-md-6 '>
                                            <img src='./Assets/product-3.png' className='text-center fluid-img ms-4 prd-img' />
                                        </div>

                                        <div className='col-md-6 px-5'>
                                            <h3 style={{ color: '#0A0080' }} className='text-start '>IT7900P High-Performance Regenerative Grid Simulator</h3>

                                            <p></p>
                                            <div className='text-dark text-start fs-5'>
                                                <p class="">Adopting advanced SiC technology, the IT7900P series high-performance Regenerative grid simulator provides an all-in-one test solution that can be used not only as a grid simulator and four-quadrant power amplifier but also as a four-quadrant regenerative AC/DC electronic load.

                                                </p>

                                                <button className='btn p-2 fs-5' style={{ backgroundColor: '#E22D2C', color: '#fff', }}>Download</button>

                                            </div>

                                        </div>

                                    </div>
                                    <Carousel.Caption>

                                    </Carousel.Caption>
                                </Carousel.Item> */}
                            </Carousel>

                        </div>

                        {/* find the Right Engeering */}

                        {/* <div className='container-fluid  py-5' style={{backgroundColor:'#E6E6E6'}} >
                            <div className='container'>
                                <h2 className='text-center'  style={{ color: '#0A0080', fontWeight: 'bold', marginBottom: '40px' }}>Find the Right Engineering & Automation Solutions</h2>

                                <div className='row'>
                                    <div className='col-md-4 text-center'>
                                        <div className='' style={{width:'90px', height:'90px',backgroundColor:'#E22D2C', borderRadius:'50%', marginLeft:'38%',position:'relative',top:'36px',zIndex:'5'}}><img src='../Assets/AUTOMATED-TESTING.png'width='60px' className='mt-3'/></div>
                                    <div class="card border-0 card1" style={{borderRadius:'10px'}}>
                                        <div class="card-body mt-5">
                                            <h5 class="card-title fw-bold mb-3">AUTOMATED TESTING</h5>
                                            
                                            <p class="card-text fs-5">
                                             Comprehensive range of automated testing solutions include thermal systems testing, electric vehicle testing and customized electrical/ electronics testing solutions.
                                                </p>
                                            
                                        </div>
                                    </div>
                                    </div>

                                    <div className='col-md-4 text-center'>
                                        <div className='' style={{width:'90px', height:'90px',backgroundColor:'#E22D2C', borderRadius:'50%', marginLeft:'38%',position:'relative',top:'36px',zIndex:'5'}}><img src='../Assets/AVIONICS.png'width='60px' className='mt-3'/></div>
                                    <div class="card border-0 card1" style={{borderRadius:'10px'}}>
                                        <div class="card-body mt-5">
                                            <h5 class="card-title fw-bold mb-3">POWER MONITORING</h5>
                                            
                                            <p class="card-text fs-5">
                                            Monitor power quality and energy consumption of critical systems and remote assets using IEC/ANSI-certified instruments configured for your application.
                                                </p>
                                            
                                        </div>
                                    </div>
                                    </div>

                                    <div className='col-md-4 text-center'>
                                        <div className='' style={{width:'90px', height:'90px',backgroundColor:'#E22D2C', borderRadius:'50%', marginLeft:'38%',position:'relative',top:'36px',zIndex:'5'}}><img src='../Assets/PROCESS-AUTOMATION.png'width='60px' className='mt-3'/></div>
                                    <div class="card border-0 card1" style={{borderRadius:'10px'}}>
                                        <div class="card-body mt-5">
                                            <h5 class="card-title fw-bold mb-3">INDUSTRIAL AUTOMATION</h5>
                                            
                                            <p class="card-text fs-5 mb-4">
                                            Industrial Automation solutions encompass factory process automation & control, condition monitoring, IoT and energy management.
                                                </p>
                                            
                                        </div>
                                    </div>
                                    </div>
                                </div>

                            </div>
                        </div> */}

                        {/* Our partner code from here */}

                        {/* <div className='container py-5'>
                        <h2 className='text-center'  style={{ color: '#0A0080', fontWeight: 'bold', marginBottom: '40px' }}>Our Partners</h2>
                          <div className=''>
                          <Carousel data-bs-theme="dark">
                          <Carousel.Item style={{ backgroundColor: 'inherit' }}>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <img src='./Assets/par-1.png' width='200' height='70px'/>
                                        </div>
                                        <div className='col-md-3'>
                                            <img src='./Assets/par-2.jpg'  width='200'height='70px'/>
                                        </div>
                                        <div className='col-md-3'>
                                            <img src='./Assets/par-3.png' width='200' height='70px'/>
                                        </div>
                                        <div className='col-md-3'>
                                            <img src='./Assets/par-4.png' width='200' height='70px' />
                                        </div>
                                        
                                    </div>
                                    <Carousel.Caption>

                                    </Carousel.Caption>
                                </Carousel.Item>

                                <Carousel.Item style={{ backgroundColor: 'inherit' }}>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <img src='./Assets/par-5.png' width='200' height='70px'/>
                                        </div>
                                        <div className='col-md-3'>
                                            <img src='./Assets/par-6.png'  width='200' height='70px'/>
                                        </div>
                                        <div className='col-md-3'>
                                            <img src='./Assets/par-7.jpeg' width='200'height='70px' />
                                        </div>
                                        <div className='col-md-3'>
                                            <img src='./Assets/par-8.png' width='200'height='70px' />
                                        </div>
                                    </div>
                                    
                                </Carousel.Item>
                                </Carousel>
                                
                            </div>                                
                        </div> */}

                        {/* News & insights */}


                        <div className='container-fluid  py-5' style={{backgroundColor:'#E6E6E6'}} >
                            <div className='container'>
                                <h2 className='text-center'style={{ color: '#0A0080', fontWeight: 'bold', marginBottom: '40px' }}>Our Best Products</h2>

                                <div className='row'>
                                    <div className='col-md-4 mb-4 data-aos="fade-right"'>
                                      
                                    <div class="card border-0 p-3 card1" style={{borderRadius:'10px'}}>
                                    <img src="../Assets/battery.png" class="card-img-top " alt="..." />
                                        <div class="card-body mt-3">
                                            <h5 class="card-title fw-bold mb-3 text-center">Battery Tester</h5>
                                            
                                            {/* <button className='btn p-1 px-3 fs-5' style={{ backgroundColor: '#E22D2C', color: '#fff', }}>Learn More</button> */}
                                            
                                        </div>
                                    </div>
                                    </div>

                                    <div className='col-md-4 mb-4'>
                                       
                                    <div class="card border-0 p-3 card1" style={{borderRadius:'10px'}}>
                                    <img src="../Assets/multi.png" class="card-img-top " alt="..." />
                                        <div class="card-body mt-3">
                                            <h5 class="card-title fw-bold mb-3 text-center" style={{textAlign:'justify'}}>
                                            Multichannel temperature Data Logger
                                                </h5>
                                            
                                            {/* <button className='btn p-1 px-3 fs-5' style={{ backgroundColor: '#E22D2C', color: '#fff', }}>Learn More</button> */}
                                            
                                        </div>
                                    </div>
                                    </div>

                                    <div className='col-md-4'>
                                       
                                    <div class="card border-0 p-3 card1" style={{borderRadius:'10px'}}>
                                    <img src="../Assets/micro_oh.png" class="card-img-top" alt="..." />
                                        <div class="card-body mt-3">
                                            <h5 class="card-title fw-bold mb-3 text-center" style={{textAlign:'justify'}}>
                                            	Microohm Meter
                                                </h5>
                                            
                                            {/* <button className='btn p-1 px-3 fs-5' style={{ backgroundColor: '#E22D2C', color: '#fff', }}>Learn More</button> */}
                                            
                                        </div>
                                    </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                     <div className='col-md-12'>
                     <Whatapp />
                     </div>
                   <Footer />
                  

                    </div>


                </div>




            </div>


        </>
    )
}

export default Home