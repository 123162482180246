import { useState } from 'react'
import Header from './Header'
import Nav from 'react-bootstrap/Nav';
import Footer from './Footer';
import Whatapp from '../cmp/whatapp'
import { Link } from 'react-router-dom';

const Batterysamulator = () => {

    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    }

    return (

        <>
            <Header />
            <div>
                <div className='container-fluid p-0'>
                    <div className='careers_bg2 p-4 me-auto'>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item "><a href="#" className='fs-5 '>Home</a></li>
                                <li class="breadcrumb-item fs-5">Battery Simulator And SMU Equipments</li>
                                {/* <li class="breadcrumb-item active" aria-current="page">Data</li> */}

                            </ol>
                        </nav>
                    </div>
                </div>


                {/* <div className='container py-5'>
      <Nav variant="pills" defaultActiveKey="/home">
      <Nav.Item>
        <Nav.Link href="#tab-1">Automation Test Equipment</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-1">Industrial Automation</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-2">Power Moniterning Solution</Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link eventKey="link-3">Test Benches & Lab Solutions</Nav.Link>
      </Nav.Item>
      <Nav.Item>
       
      </Nav.Item>
    </Nav>

      </div> */}
                <div className='bg-light'>
                    <div className='container py-5'>
                        <div>
                            <ul className="nav" variant="pills" >
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`} onClick={() => handleTabClick('tab1')} style={{ border: 'none' }}>
                                        Battery Simulators
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`} onClick={() => handleTabClick('tab2')} style={{ border: 'none' }}>
                                        Semiconductor Test Series
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`} onClick={() => handleTabClick('tab3')} style={{ border: 'none' }}>
                                        Fuel Cell Test Series
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab4' ? 'active' : ''}`} onClick={() => handleTabClick('tab4')} style={{ border: 'none' }}>
                                        Supercapacitor Test Series
                                    </button>
                                </li>
                            </ul>

                            <div className="tab-content">
                                {activeTab === 'tab1' && <div className='border p-3 px-4'>



                                    <div className='row'>

                                        <div className='col-md-6'>

                                            <img src='../Assets/Battery-simu.jpg' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-6'>
                                            <h3>
                                                N83624 Series Twenty-Four-Channel Battery Simulator
                                            </h3>
                                            <p className='fs-5'>
                                                N83624 is a programmable battery simulator with low-power, multi-channel and high-accuracy, suitable for BMS/CMS test.It can also be used as a multi-channel high accuracy DC power supply. It is highly integrated, single device with up to 24 channels. Each channel is isolated, available for multi-channel series connection. N83624 is equipped with high-definition color LCD screen, available for local operation. Users can also set the voltage & current for each channel on application software, which is easy to use and can meet the needs of multi-channel and multi-data. The software can also provide graphs, data analysis and report function.
                                            </p>
                                            <div className='mb-4'> <button className='btn btn-secondary py-2 fs-4 border-0' style={{ backgroundColor: '#e21108' }}>More Info</button> </div>

                                        </div>
                                        <div>
                                            <h4 className='mb-3 text-start'>Main features</h4>
                                            <hr />
                                            <ul>
                                                <li className='fs-5'>
                                                    Voltage range: 0-6V/0-15V
                                                </li>
                                                <li className='fs-5'>
                                                    Current range: 0-1A/0-3A/0-5A
                                                </li>
                                                <li className='fs-5'>
                                                    Single device with up to 24 channels, each channel isolated, series connection available
                                                </li>
                                                <li className='fs-5'>
                                                    Fast communication response , within 10ms for all channels programming response
                                                </li>
                                                <li className='fs-5'>
                                                    Professional application software, with data analysis and report
                                                </li>
                                                <li className='fs-5'>
                                                    High-definition color LCD screen, available for local operation
                                                </li>
                                                <li className='fs-5'>
                                                    Standard 19-inch 3U, available for rack installation
                                                </li>
                                                <li className='fs-5'>
                                                    LAN port and RS232 interface; dual LAN ports, convenient for cascade application
                                                </li>
                                                <li className='fs-5'>
                                                    μA level current measurement
                                                </li>
                                                <li className='fs-5'>
                                                    Remote sense for high accuracy
                                                </li>
                                                <li className='fs-5'>
                                                    Fast dynamic response, voltage rise time less than 20μs(For 6V specification)
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <h4 className='mb-3 text-start'>Application Fields</h4>
                                            <hr />
                                            <ul>
                                                <li className='fs-5'>
                                                    BMS/CMS test for new energy vehicle, UAV and energy storage
                                                </li>


                                                <li className='fs-5'>
                                                    Portable consumer Electronics R&D and production, such as mobiles, bluetooth earphones, smartwatch, etc.
                                                </li>
                                                <li className='fs-5'>
                                                    Calibration of voltage acquisition device, such as fuel cell voltage monitor
                                                </li>


                                            </ul>
                                        </div>

                                        <div>
                                            <h4 className='mb-3 text-start'>Functions & Advantages</h4>
                                            <hr />
                                            <h6 className='fw-bold'>Ultra-high accuracy</h6>
                                            <p className='fs-5'>
                                                N83624 current resolution is as low as 0.1μA. Ultra-high accuracy, ultra-low ripple and noise index
                                                make N83624 an ideal choice for battery simulation application. The ultra-high accuracy of N83624
                                                output and measurement can be directly used in product calibration and test, eliminating the use of
                                                external high-accuracy measuring instruments and saving cost for users.
                                            </p>
                                            <img src='../Assets/battery-1.png' style={{width:'100%'}} />
                                            <h6 className='fw-bold'>Ultra-high integration</h6>
                                            <p className='fs-5'>
                                                N83624 integrates up to 24 channels that can be connected in series mode in 19-inch 3U size, providing a
                                                compact solution for ATE test systems in BMS, CMS and similar large-scale high-density production sites.
                                            </p>
                                            <img src='../Assets/battery-2.png' style={{width:'100%'}}  />
                                            <h6 className='fw-bold'>Battery simulation suitable for BMS chips test of various specifications</h6>
                                            <p className='fs-5'>
                                                N83624 series battery simulators have multiple functions and features, supporting Source, All CH, Charge, SOC Test,SEQ, Graph, etc.

                                                One device can achieve multiple uses, streamline test equipment and optimize test procedures. N83624's internal circuit is optimized for different chips, which can be adapted to test BMS chips of various specifications.
                                            </p>
                                            <img src='../Assets/battery-3.png'  style={{width:'100%'}} />
                                            <h6 className='fw-bold'>Fast dynamic response</h6>
                                            <p className='fs-5'>
                                                N83624 series has fast dynamic response capability. The response time of load varying from 10% to 90% and voltage recovering within 50mV of previous voltage is less than 100μs (For 6V specification),
                                                which can ensure the rising waveform of voltage or current is high-speed and without overshoot, and provide stable power for the DUT. This feature can meet the demand for product test with strict power requirements.
                                            </p>

                                           <div style={{width:'100%'}}> <img src='../Assets/battery-4.jpg' style={{width:'100%'}}  /></div>

                                            <img src='../Assets/battery-5.jpg' style={{width:'100%'}}  />
                                            <h6 className='fw-bold'>LAN port and RS232 interface, easy for cascade application</h6>
                                            <p className='fs-5'>
                                                N83624 series supports LAN port and RS232 interface. LAN port is designed with dual ports, which can be used forremote control and also for cascade application.
                                            </p>
                                            <img src='../Assets/battery-6.png'  style={{width:'100%'}} />
                                        </div>
                                        <div className='px-4'>
                                            <div className='auto-img d-flex justify-content-center align-items-center'>
                                            <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>



                                            </div>
                                        </div>
                                    </div>



                                </div>

                                }


                                {activeTab === 'tab2' && <div className='border p-3 bg-light'>

                                    <div className='row'>

                                        <div className='col-md-6'>

                                            <img src='../Assets/semi-con.jpg' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-6'>
                                            <h3>
                                                N9244 Series High Precision Multi Channel Programmable DC Power Supply
                                            </h3>
                                            <p className='fs-5'>
                                                N9244 series is a constant current programmable power supply with multi-channel, high integration, high performance,a standard ½ 19 inch 2U chassis with 44 channels constant-current source output. It supports both desktop and system applications. N9244 series voltage specification is 40V, support constant current 5mA output, no leakage current under OFF state.
                                                NGI provides standard upper computer software to support large-scale testing and LAN/RS232 communication control. Users can carry out secondary development according to testing process and testing requirements, which is convenient for integrated system testing.
                                            </p>
                                            <div className='mb-4'> <button className='btn btn-secondary py-2 fs-4 border-0' style={{ backgroundColor: '#e21108' }}>More Info</button> </div>

                                        </div>
                                        <div>
                                            <h4 className='mb-3 text-start'>Main features</h4>
                                            <hr />
                                            <ul>
                                                <li className='fs-5'>
                                                    Voltage Range:0~40 V
                                                </li>
                                                <li className='fs-5'>
                                                    Current Range:0~5 mA
                                                </li>
                                                <li className='fs-5'>
                                                    Current Resolution 1μA
                                                </li>
                                                <li className='fs-5'>
                                                    Constant Current Accuracy:0.1%+20μA
                                                </li>
                                                <li className='fs-5'>
                                                    All-round protection to ensure the safety of instruments and products under test
                                                </li>
                                                <li className='fs-5'>
                                                    4.3-inch HD color screen, simple operation interface, easy to use
                                                </li>
                                                <li className='fs-5'>
                                                    Communication supports LAN, RS232, and standard MODBUS protocols
                                                </li>
                                                <li className='fs-5'>
                                                    No Leakage Current under OFF state
                                                </li>
                                                <li className='fs-5'>
                                                    High Integration, single unit with 44 channels


                                                </li>
                                                <li className='fs-5'>
                                                    Adopt modular architecture, easy maintenance
                                                </li>

                                            </ul>
                                        </div>

                                        <div>
                                            <h4 className='mb-3 text-start'>Application Fields</h4>
                                            <hr />
                                            <ul>
                                                <li className='fs-5'>
                                                    Mini LED Luminescent Device
                                                </li>


                                                <li className='fs-5'>
                                                    LED Lamp Beads
                                                </li>
                                                <li className='fs-5'>
                                                    LED Light Bar
                                                </li>
                                                <li className='fs-5'>
                                                    LED Chips
                                                </li>

                                            </ul>
                                        </div>

                                        <div>
                                            <h4 className='mb-3 text-start'>Functions & Advantages</h4>
                                            <hr />
                                            <h5 className='fw-bold'>Constant current mode, Fast Test</h5>
                                            <p className='fs-5 '>
                                                With the continuous breakthrough of technology, LED pixel spacing is further reduced, Mini LED added a
                                                lot of new development space to the industry. The chip size of Mini LED is 10μm level, which means that the
                                                demand of traditional side entry light source display for lamp beads may be between 20~30 pcs, while the
                                                demand of Mini LED display for lamp beads will be increased to hundreds or even thousands, then the channels
                                                number of test instrument also needs more. N9244 series supports one key to enter constant current mode,
                                                current unified setting,
                                                realize 44 channels fast test,it can improve the test efficiency, greatly reduce the production cost.
                                            </p>
                                            <h5 className='fw-bold'>
                                                HD Color Screen, Show all information
                                            </h5>
                                            <p className='fs-5 '>
                                                The N9244 series uses a large 4.3-inch HD color screen, which displays the information of read back
                                                voltage& current, current setting, and multilevel menu. The read back between different channels can
                                                be fast switched, and it can display voltage and current information for 22 channels at the same time,
                                                and support USB flash disk quick screenshot, real-time storage of test data.
                                            </p>
                                            <div>
                                                <img src='../Assets/sim-1.png' style={{width:'100%'}}  />
                                            </div>
                                            <h5 className='fw-bold'>
                                                Modular design,Easy Maintenance&Expansion
                                            </h5>
                                            <p className='fs-5 '>
                                                N9244 series adopts modular design, a single device has 2 output interfaces, each of which has 22 channels.
                                                The use efficiency of the instrument can be greatly
                                                improved by customized connectors, and it greatly reduce the tedious work of traditional test equipment wiring.
                                            </p>
                                            <h5 className='fw-bold'>
                                                Product Demension
                                            </h5>
                                            <div>
                                                <img src='../Assets/sim-2.png' style={{width:'100%'}}  />
                                            </div>
                                        </div>
                                        <div className='px-4'>
                                            <div className='auto-img d-flex justify-content-center align-items-center'>
                                            <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>



                                            </div>
                                        </div>
                                    </div>




                                </div>}
                                {activeTab === 'tab3' && <div className='border p-3'>

                                    <div className='row'>

                                        <div className='col-md-6'>

                                            <img src='../Assets/fuel-4.jpg' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-6'>
                                            <h3>
                                                N1200 Series Cell Voltage Monitor
                                            </h3>
                                            <p className='fs-5'>
                                                N1200 series cell voltage monitor is specially developed by NGI for fuel cell R&D and production.
                                                It is with compact size,high integration, high reliability and fast data transmission. N1200
                                                standalone supports up to 200 channels. More channelscan be tested simultaneously under cascade
                                                mode. Voltage acquisition ranges are from -5V to +5V, which completelycovers the voltage range of
                                                fuel cell. Real-time voltage data of total 200 channels can be uploaded within 50ms by adopting100M
                                                Ethernet communication.
                                            </p>
                                            <div className='mb-4'> <button className='btn btn-secondary py-2  px-3 fs-4 border-0' style={{ backgroundColor: '#e21108' }}>More Info</button> </div>

                                        </div>
                                        <div>
                                            <h4 className='mb-3 text-start'>Main features</h4>
                                            <hr />
                                            <ul>
                                                <li className='fs-5'>
                                                    Voltage acquisition range: -2.5V~+2.5V, -3V~+3V,-5V~+5V
                                                </li>
                                                <li className='fs-5'>
                                                    Voltage acquisition accuracy : 1mV, 2mV
                                                </li>
                                                <li className='fs-5'>
                                                    High integration, standalone with up to 200 channels
                                                </li>
                                                <li className='fs-5'>
                                                    Fast data transmission, within 50ms for total 200 channels transmission
                                                </li>
                                                <li className='fs-5'>
                                                    100M Ethernet communication
                                                </li>
                                                <li className='fs-5'>
                                                    Easy system integration, supporting standard Modbus protocol, convenient for integration into PLC and othercontrol systems
                                                </li>
                                                <li className='fs-5'>
                                                    Compact size, standard 19-inch 1U, convenient for rack installation
                                                </li>

                                            </ul>
                                        </div>

                                        <div>
                                            <h4 className='mb-3 text-start'>Application Fields</h4>
                                            <hr />
                                            <ul>
                                                <li className='fs-5'>
                                                    Fuel cell voltage monitoring
                                                </li>


                                                <li className='fs-5'>
                                                    Battery cell voltage monitoring
                                                </li>


                                            </ul>
                                        </div>

                                        <div>
                                            <h4 className='mb-3 text-start'>Functions & Advantages</h4>
                                            <hr />
                                            <h5 className='fw-bold'>Standalone supporting 200 channels of voltage monitoring</h5>
                                            <p className='fs-5 '>
                                                N1200 CVM integrates 200 channels in a 19-inch 1U standard chassis. For more channel testing requirements, multiple sets of N1200 can be used for
                                                simultaneous testing, which not only saves space for customers, but also improves convenience.
                                            </p>
                                            <h5 className='fw-bold'>
                                                200 channels data update time 50ms
                                            </h5>
                                            <p className='fs-5 '>
                                                The ultra-fast sampling speed of N1200 and the fast transmission speed make the time from acquisition to update of 200 channels data up to 50ms.
                                            </p>
                                            <div>
                                                <img src='../Assets/fuel-2.png' style={{width:'100%'}}  />
                                            </div>
                                            <h5 className='fw-bold'>
                                                Voltage acquisition accuracy up to 1mV
                                            </h5>
                                            <p className='fs-5 '>
                                                N1200 CVM uses a stable detection circuit to measure the voltage of fuel cell with an accuracy of up to ±1mV,enabling real-time high-accuracy
                                                voltage detection of single fuel cell. The temperature coefficient is as low as 50ppm/℃, and the error caused by every ten degrees Celsius does not exceed 0.05%, which enables N1200 to maintain high precision in application.
                                            </p>

                                            <div>
                                                <img src='../Assets/fuel-3.png'  style={{width:'100%'}} />
                                            </div>
                                        </div>
                                        <div className='px-4'>
                                            <div className='auto-img d-flex justify-content-center align-items-center'>
                                            <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>


                                            </div>
                                        </div>
                                    </div>




                                </div>}

                                {activeTab === 'tab4' && <div className='border p-3'>



                                    <div className='row'>

                                        <div className='col-md-6'>

                                            <img src='../Assets/sup-capa-5.jpg' style={{ width: '100%' }} />

                                        </div>

                                        <div className='col-md-6'>
                                            <h3>
                                                N8310 Supercapacitor Self-discharge Tester
                                            </h3>
                                            <p className='fs-5'>
                                                N8310 is an analysis and diagnostic instrument specially developed by NGI for supercapacitor self-discharge test. N8310 consists of three parts: test instrument, application software and test fixture. It can test the self-discharge parameters of various types of supercapacitors under the set voltage. N8310 can be widely used in the R&D, production and quality inspection of supercapacitors, with the advantages of high cost performance, compact size and high accuracy.

                                                N8310 uses a standard 19-inch chassis with 2U height, which is convenient for integration into automation test platforms for R&D and production, and can also be used separately.
                                            </p>
                                            <div className='mb-4'> <button className='btn btn-secondary py-2  px-3 fs-4 border-0' style={{ backgroundColor: '#e21108' }}>More Info</button> </div>

                                        </div>
                                        <div>
                                            <h4 className='mb-3 text-start'>Main features</h4>
                                            <hr />
                                            <ul>
                                                <li className='fs-5'>
                                                    Voltage range: 0-6V
                                                </li>
                                                <li className='fs-5'>
                                                    Resolution up to 24 bits, accuracy up to 0.02%
                                                </li>
                                                <li className='fs-5'>
                                                    Charging current up to 1A, meeting the speed requirement of most supercapacitors
                                                </li>
                                                <li className='fs-5'>
                                                    Single device with up to 24 channels
                                                </li>
                                                <li className='fs-5'>
                                                    Communication interface: LAN/RS485
                                                </li>
                                                <li className='fs-5'>
                                                    Data export and analysis
                                                </li>


                                            </ul>
                                        </div>



                                        <div>
                                            <h4 className='mb-3 text-start'>Functions & Advantages</h4>
                                            <hr />
                                            <h5 className='fw-bold'>Self-discharge test</h5>
                                            <p className='fs-5 '>
                                                N8310 can provide multi-channel self-discharge parameter test function.
                                                Based on programmable CV/CC output capability and high-precision voltage acquisition capability,
                                                N8310 allows users to set parameters such as voltage, current, time, and sampling interval.
                                                The test results can be stored in database and exported in the formats of Excel and JPG.
                                            </p>
                                            <div>
                                                <img src='../Assets/sup-capa-2.png' style={{width:'100%'}} />
                                            </div>
                                            <h5 className='fw-bold'>
                                                Test fixture
                                            </h5>
                                            <p className='fs-5 '>
                                                Considering the test application scenarios of different scales, NGI provides two types of test fixture: Kelvin clamp and 12-channel special fixture. Both test fixtures support four-wire connection.
                                            </p>
                                            <div>
                                                <img src='../Assets/sup-capa-3.png' style={{width:'100%'}} />
                                            </div>
                                            <h5 className='fw-bold'>
                                                Application software
                                            </h5>
                                            <p className='fs-5 '>
                                                N8310 software adopts a platform design, which allows the users to customize the test process according to their requirements.

                                                Office-like interface, independent display of each channel, supporting voltage and current waveform generation, and result display in tabular form make this professional software multifunctional and easy-to-use.

                                                N8310 software supports data search, data import & export, and Excel report generation.
                                            </p>

                                            <div>
                                                <img src='../Assets/sup-capa-4.png' />
                                            </div>
                                        </div>
                                        <div className='px-4'>
                                            <div className='auto-img d-flex justify-content-center align-items-center'>
                                            <button className='btn p-1 fs-5 px-3' style={{ backgroundColor: '#E22D2C', color: '#fff', }}><Link to='/contactus' className='text-decoration-none text-white'>Talk with Us</Link></button>

                                            </div>
                                        </div>
                                    </div>

                                </div>}
                            </div>
                        </div>
                    </div>
                </div>






            </div>
            <Whatapp />
            <Footer />


        </>

    )
}

export default Batterysamulator