import React from 'react';
import  '../cmp/css/Header.css'
const phoneNumber = '918088073675'; 
const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}`;

const WhatsAppIcon = () => {
  return (
    <button className="whatsapp-button">
      <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
        <img src="whatapp.png" alt="WhatsApp Icon" />
       
      </a>
    </button>
  );
};

export default WhatsAppIcon;